import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';

import room from '../../assets/images/pos.png'

const MainWrapper = styled.div`
  margin: 0px 0px;
  max-width: 100%;
  border-radius: 20px;
  padding: 20px;
  grid-template-columns: 20% 80%;
  
`;

const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  padding: 20px 200px;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px 00px;

  }
`;

const AuditImage = styled.img`
  margin-bottom: 20px;
  width: 100%;
`;

const AuditContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
`;

const Title = styled.h1`
  color: #fff;
  font-weight: 500;
  font-size: 35px;
  font-family: var(--font-bold);

  span {
    color: #ffba00;
  }

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const Description = styled.p`
  font-size: 17px;
  color: #ccc;
  margin: 20px 0;
`;



const BlockThree: React.FC = () => {
  return (
    <div className="blockthree-wrapper apoplps" style={{ margin: 0 }}>
      <MainWrapper>
        <Audits>

          <AuditContent>
            <Title>Ai Powered POS <span>System</span></Title>
            <Description>
            At Baaz, we're revolutionizing the way you transact by making crypto payments seamless and accessible. Our innovative checkout system allows you to pay with cryptocurrency effortlessly, ensuring speed, security, and convenience. Whether you're shopping online or dining out, our platform integrates advanced blockchain technology to simplify digital payments. Say goodbye to complexities and experience a future-ready way to pay. Your crypto, your control—made easy with Baaz.</Description>    
            
                    <button

onClick={() => window.location.href = "https://docs.baazcoin.com"}
            className={`button-prim `}
            style={{width:'fit-content',cursor:'pointer'}}
          >
            <div style={{display:'flex',fontSize:16,justifyContent:'center',alignItems:'center',margin:0,padding:0}}>
Dig Deeper            </div>
          </button>
          </AuditContent>
          <AuditImage src={room} />

        </Audits>
      </MainWrapper>
    </div>
  );
};

export default BlockThree;
