import { MaxUint256, ethers, formatEther, parseEther } from "ethers";
import presale_abi from "./abis/presale.json";
import { PRESALE_ADDRESS } from "./Address";

const getSigner = async (address: string) => {
  const windowEth = window as any;
  const provider = new ethers.BrowserProvider(windowEth.ethereum);
  return await provider.getSigner(address);
};

// Presale Contract Instance
const getPresaleContract = (signer: ethers.Signer) => {
  return new ethers.Contract(PRESALE_ADDRESS, presale_abi, signer);
};

// Fetch Presale Details
export const fetchPresaleDetails = async (address: string) => {
  try {
    const signer = await getSigner(address);
    const presaleContract = getPresaleContract(signer);

    const bnbPrice = await presaleContract.getLatestPrice();
    const presaleStart = await presaleContract.presaleStart();
    const presaleEnd = await presaleContract.presaleEnd();
    const presaleRate = await presaleContract.presaleRate();

    const totalPurchasedUSD = await presaleContract.totalPurchasedBNB();

    return {
      bnbPrice: bnbPrice.toString(),
      presaleStart: presaleStart.toString(),
      presaleEnd: presaleEnd.toString(),
      presaleRate: presaleRate.toString(),
      totalPurchasedUSD: formatEther(totalPurchasedUSD),
    };
  } catch (error) {
    throw error;
  }
};


// Calculate Tokens for a Given BNB Amount
export const calculateTokens = async (bnbAmount: number, address: string) => {
  try {
    const signer = await getSigner(address);
    const presaleContract = getPresaleContract(signer);

    console.log("Calling calculateTokens with:", bnbAmount, address);
    const tokens = await presaleContract.calculateTokens(parseEther(bnbAmount.toString()));
    
    if (!tokens) {
      throw new Error("No response from calculateTokens");
    }

    return formatEther(tokens);
  } catch (error: any) {
    console.error("Error calculating tokens:", error.reason || error.message);
    throw error;
  }
};



// Buy Tokens
export const buyTokens = async (bnbAmount: string, address: string) => {
  try {
    const signer = await getSigner(address);
    const presaleContract = getPresaleContract(signer);

    const tx = await presaleContract.buyTokens({
      value: parseEther(bnbAmount),
    });
    await tx.wait();
    return tx.hash; // Assuming `txn.hash` is the transaction hash

    console.log("Tokens purchased successfully.");
  } catch (error) {
    console.error("Error buying tokens:", error);
    throw error;
  }
};

// Withdraw Funds (Owner Only)
export const withdrawFunds = async (address: string) => {
  try {
    const signer = await getSigner(address);
    const presaleContract = getPresaleContract(signer);

    const tx = await presaleContract.withdrawFunds();
    await tx.wait();

    console.log("Funds withdrawn successfully.");
  } catch (error) {
    console.error("Error withdrawing funds:", error);
    throw error;
  }
};

// Withdraw Tokens (Owner Only)
export const withdrawTokens = async (address: string) => {
  try {
    const signer = await getSigner(address);
    const presaleContract = getPresaleContract(signer);

    const tx = await presaleContract.withdrawTokens();
    await tx.wait();

    console.log("Tokens withdrawn successfully.");
  } catch (error) {
    console.error("Error withdrawing tokens:", error);
    throw error;
  }
};
