import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';

import room from '../../assets/images/rb_62072 1.png'

const MainWrapper = styled.div`
  margin: 50px 0px;
  max-width: 100%;
  border-radius: 20px;
  padding: 20px;
  grid-template-columns: 20% 80%;
  
`;

const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  padding: 20px 50px;
  grid-template-columns: 1.5fr 1fr;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px 00px;

  }
`;

const AuditImage = styled.img`
  margin-bottom: 20px;
  width: 100%;
  height: auto;
`;

const AuditContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
`;

const Title = styled.h1`
  color: #fff;
  font-weight: 500;
  font-size: 55px;
  font-family: var(--font-bold);

  span {
    color: #ffba00;
  }

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const Description = styled.p`
  font-size: 17px;
  color: #ccc;
  margin: 20px 0;
`;



const BlockThree: React.FC = () => {
  return (
    <div className="blockthree-wrapper apoplps" style={{ margin: 0 }}>
      <MainWrapper>
        <Audits>

          <AuditContent>
            <Title>Join Us and Build the Future of <span>Decentralization</span></Title>
            <Description>
            Are you ready to make a real difference in the world of blockchain and decentralized finance? Do you want to work on groundbreaking projects that will transform industries and improve the lives of people around the world? If so, we want you on our team.<br/>
We’re hiring across a variety of roles, from blockchain developers to AI engineers to smart contract specialists. Apply now and be part of the future of decentralized technology!   <br/><br/>Apply Now to be part of Baaz Coin’s mission to revolutionize the world of blockchain and digital payments. Together, we’ll build a decentralized future!         </Description>
            <button
             onClick={() => window.location.href = "mailto:contact@baazcoin.com"}
            className={`button-prim `}

            style={{width:'fit-content',marginTop:20}}
          >
            <div style={{display:'flex',fontSize:16,justifyContent:'center',alignItems:'center',margin:0,padding:0}}>
            Apply Now
            </div>
          </button>
          </AuditContent>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>          <AuditImage src={room} />
</div>

        </Audits>
      </MainWrapper>
    </div>
  );
};

export default BlockThree;
