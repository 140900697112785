import React from 'react';
import styled from 'styled-components';

// Styled Components
const ProgressWrapper = styled.div`
  width: 100%;
  background-color: rgba(38, 38, 38, 0.6); /* #262626 with 10% opacity */
  border-radius: 100000px;
  border:5px solid rgba(38, 38, 38, 0.2);
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;

  }
  
`;

const ProgressBar = styled.div<{ progress: number }>`
  height: 15px;
  width: ${(props) => props.progress}%;
  background-color: #fabb00; // Custom yellow background
  transition: width 0.3s ease-in-out;
  border-radius: 10px;
  @media (max-width: 768px) {
    height: 10px;

  }
`;

// ProgressBar component
interface ProgressBarProps {
  progress: number;
}

const ProgressBarComponent: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <ProgressWrapper>
      <ProgressBar progress={progress} />
    </ProgressWrapper>
  );
};

export default ProgressBarComponent;
