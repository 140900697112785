import React, { useState } from 'react';
import './Hero.scss';
import Map from '../Map';
import ReactDOMServer from 'react-dom/server';
import { CircularProgressbar,CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ReactComponent as Shield } from '../../assets/images/Shield.svg';
import styled from 'styled-components';
import Front from "../../assets/images/front.png"
import bg1 from '../../assets/images/Rectangle.png';
import hero from '../../assets/images/img-hero.png';

const percentage = 66;
const percentage2 = 78;
const trailWidth = 2; // Desired trail width in pixels

const Main = styled.div`
display:grid;
height:100%;
grid-template-columns:  50% 50%;
@media (max-width: 768px) {
  grid-template-columns:1fr;
  row-gap:20px;

}
.shield{
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;

  div{
    background-image: url(${bg1});
    background-size: contain;
    width: calc(50vw * 0.95); 
    height: calc(50vw * 0.8915 * 0.95);
    max-width: calc(567.7925px * 0.95); 
    max-height: calc(506.11275px * 0.95); 
    background-repeat: no-repeat;
    @media (max-width: 768px) {
      background-image: url(${bg1});
      background-size: contain;
      width: calc(80vw ); 
      height: calc(80vw * 0.8915 );
      max-width: calc(567.7925px ); 
      max-height: calc(506.11275px ); 
      background-repeat: no-repeat;
    
    }
  }
  img{
    width:100%;
    @media (max-width: 768px) {
      width:100%;
    
    }
  }
}


.texture{
  height:100%;
  display:flex;
  line-height:1.3;
  padding-left:70px;
  justify-content:center;
  align-items:center;
  @media (max-width: 768px) {
    padding-left:10px;
padding-top:100px;
  
  }


  h1{
    font-size:65px;
    font-weight:800;
    font-family: var(--font-bold);
    color:#fff;
    margin-top:30px;
     @media (max-width: 768px) {
      font-size:30px;

  
  }
  }

  p{
    font-size:18px;
    font-weight:300;
    color:#a0a0a0;
    margin-top:30px;
    margin-bottom:30px;
  }
}


`;



const Buttons = styled.div`
width:80%;
display:flex;
grid-template-columns:1fr 1fr;
column-gap:20px;
font-size:55px;


a{
  width:100%;
  padding-top:9px;
  padding-bottom:9px;
  background-color:transparent;
  color:#ffba00;
  font-size:18px;

  border:1px solid #ffba00;
  border-radius:5px;
  text-align:center;
}
@media (max-width: 768px) {
  display:grid;

  grid-template-columns:1fr;
  a{
    width:100%;
    padding-top:9px;
    padding-bottom:9px;
    background-color:transparent;
    color:#ffba00;
    font-size:18px;
    border:1px solid #ffba00;
    border-radius:5px;
    text-align:center;
  }

}
`;








const Hero: React.FC = () => {
  const progress = 75; // Example progress value

  const [checked, setChecked] = React.useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="hero-wrapper">

      <div className="box-wrappers"> {/* Added 'waves-background' class */}
          <Main>
            <div className="texture"><div><h1>Join <span style={{color:'#ffba00'}}>Baaz</span> Shape the Future of Decentralization</h1>
            <p style={{width:"80%"}}>At Baaz, we believe that the future is decentralized—and we’re not just talking about finance. Our mission is to build an ecosystem that empowers individuals, businesses, and institutions to transact, create, and communicate in a truly decentralized world. By leveraging the power of blockchain technology, AI, and cutting-edge security measures, we aim to revolutionize industries from payments to commerce to software development.

</p>

</div>
            </div>
       
          </Main>

        </div>

    </div>
  );
};

export default Hero;
