import React from 'react';
import styled from 'styled-components';

import roadmap from '../../assets/images/baaz-roadmap.png';

const MainWrapper = styled.div`
  margin: 50px 0px;
  max-width: 100%;
  border-radius: 20px;
  padding: 20px;
  grid-template-columns: 20% 80%;

  p {
    font-size: 17px;
    color: #ccc;
    margin: 20px 0;
  }

  h1 {
    text-align: center;
    color: #fff;
    font-weight: 400;
    font-size: 45px;
    font-family: var(--font-bold);
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
`;

const RoadmapWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
`;

const RoadmapImage = styled.img`
  width: auto;
  max-width: 100%;
  
`;

const BlockThree: React.FC = () => {
  return (
    <div className="blockthree-wrapper apoplpss" style={{ margin: 0 }}>
      <MainWrapper>
        <h1>
          Baaz Coin <span style={{ color: '#ffba00' }}>Roadmap</span>
        </h1>
        <RoadmapWrapper>
          <RoadmapImage src={roadmap} />
        </RoadmapWrapper>
      </MainWrapper>
    </div>
  );
};

export default BlockThree;
