import { w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Chain, configureChains, createConfig } from "wagmi";
import { bsc, sepolia } from "wagmi/chains";

export const walletConnectProjectId = "bf9397e41bf0ab99a492296a2957db54";

// Define your custom private network configuration
export const customNetwork = {
  id: 1334,
  name: "Baaz chain",
  network: "Baaz",
  nativeCurrency: {
    decimals: 18,
    name: "Baaz chain",
    symbol: "BAAZ",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.baazscan.com"],
    },
    public: {
      http: ["https://rpc.baazscan.com"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "Baaz chain",
      url: "https://baazscan.com",
    },
    default: {
      name: "Baaz chain",
      url: "https://baazscan.com",
    },
  },
};

export const isTestnet = true;

// const testnets = [bscTestnet];
// const mainnets = [bsc, customNetwork];

export const projectId = "bf9397e41bf0ab99a492296a2957db54";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc],
  [w3mProvider({ projectId: walletConnectProjectId })]
);

export const config = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({
    chains,
    projectId: walletConnectProjectId,
  }),
  publicClient,
  webSocketPublicClient,
});

export { chains };
