import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import styled, { css } from "styled-components";

import customer from "../../assets/images/1.png";
import elements from "../../assets/images/3.png";
import bitcoin from "../../assets/images/2.png";
import crown from "../../assets/images/crown.png";
import BenBack from "../../assets/images/ben-back.png";
import Arrow from "../../assets/images/arrow-right-01.png";

const MainWrapper = styled.div`
  margin: 50px 0px;
  max-width: 100%;
  border-radius: 20px;
  padding: 20px;
  grid-template-columns: 20% 80%;

  p {
    text-align: center;
    font-size: 14px;
    color: #ccc;
    margin: 0 17%;
    @media (max-width: 768px) {
      margin: 0 0%;
      font-size: 14px;
      margin-top: 10px;
    }
  }

  h1 {
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 45px;
    font-family: var(--font-bold);
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
`;

const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  padding: 20px 200px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px 30px;
  }

  .auditkyc {
    background-image: url(${BenBack});
    background-size: 100% 100%; 
    background-repeat: no-repeat;
    padding: 25px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;


    &:hover {
      transform: scale(1.05);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }

    .icon-container {
      width: fit-content;
      display: flex;
      border-radius: 10px;
      justify-content: center;
      align-items: center;

      .icon {
        width: 40px;
        height: 40px;
        margin: 0;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      font-family: var(--font-normal);
      text-align: left;
      margin-top: 10px;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    .description {
      padding: 0;
      margin: 0;
      text-align: left;
      font-size: 13px;
      margin-top: 6px;
    }

    .spacing {
      margin-top: 30px;
    }

    .explore-link {
      display: flex;
      align-items: center;
      transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */

      &:hover {
        transform: translateY(-3px); /* Moves the link slightly upwards */
        color: #ffba00; /* Changes the text color */
      }

      .explore-container {
        display: flex;
        align-items: center;
      }

      .arrow {
        width: 20px;
        height: 20px;
        margin-bottom: 0;
        transition: transform 0.3s ease; /* Smooth transition for the arrow */
      }

      &:hover .arrow {
        transform: translateX(
          5px
        ); /* Moves the arrow to the right when hovered */
      }
    }
  }

  img {
    margin-bottom: 20px;
    width: 160px;
    height: 160px;
  }
`;


const Auditss = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  padding: 20px 200px;
  grid-template-columns: 0.5fr 1fr 1fr 0.5fr;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    margin-top: -40px;

    padding: 20px 30px;
  }

  .auditkyc {
    background-image: url(${BenBack});
    background-size: 100% 100%; 
    background-repeat: no-repeat;
    padding: 25px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
      transform: scale(1.05);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }

    .icon-container {
      width: fit-content;
      display: flex;
      border-radius: 10px;
      justify-content: center;
      align-items: center;

      .icon {
        width: 40px;
        height: 40px;
        margin: 0;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      font-family: var(--font-normal);
      text-align: left;
      margin-top: 10px;

      @media (max-width: 768px) {
        font-size: 18px;
      }

      @media (max-width: 1600px) and (min-width: 1280px) {
        font-size: 18px;

      }
    }

    .description {
      padding: 0;
      margin: 0;
      text-align: left;
      font-size: 13px;
      margin-top: 6px;
    }

    .spacing {
      margin-top: 30px;
    }

    .explore-link {
      display: flex;
      align-items: center;
      transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */

      &:hover {
        transform: translateY(-3px); /* Moves the link slightly upwards */
        color: #ffba00; /* Changes the text color */
      }

      .explore-container {
        display: flex;
        align-items: center;
      }

      .arrow {
        width: 20px;
        height: 20px;
        margin-bottom: 0;
        transition: transform 0.3s ease; /* Smooth transition for the arrow */
      }

      &:hover .arrow {
        transform: translateX(
          5px
        ); /* Moves the arrow to the right when hovered */
      }
    }
  }

  img {
    margin-bottom: 20px;
    width: 160px;
    height: 160px;
  }






`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;

  svg,
  img {
    width: 50px;
    height: 50px;
    transition: transform 0.3s ease, filter 0.3s ease;

    &:hover {
      transform: scale(1.2);
      filter: brightness(1.2);
    }
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    svg,
    img {
      width: 40px;
      height: 40px;
    }
  }
`;

const BlockThree: React.FC = () => {
  return (
    <div className="blockthree-wrapper " style={{ margin: 0 }}>
      <MainWrapper>
        <h1>
          Benefits of Working with{" "}
          <span style={{ color: "#ffba00" }}>Baaz Foundation</span>
        </h1>
        <p>
          Collaborating with Baaz Foundation developers ensures access to
          cutting-edge expertise in blockchain, Web3, and AI solutions tailored
          to your project's unique needs. Our team delivers innovative,
          scalable, and secure technologies that empower your business to thrive
          in a decentralized future.
        </p>
        <Audits>
          <div className="auditkyc">
            <div className="icon-container">
              <img src={customer} className="icon" />
            </div>
            <h1 className="title">Innovation-Driven Culture</h1>
            <p className="description">
            You’ll be part of a team that is constantly pushing the boundaries of what’s possible in blockchain and decentralized finance.
            </p>
            {/* <div className='spacing'></div>
  <a href="/" className='explore-link'>
    <div className='explore-container'>
      Explore More
      <img className='arrow' src={Arrow} />
    </div>
  </a> */}
          </div>

          <div className="auditkyc">
            <div className="icon-container">
              <img src={bitcoin} className="icon" />
            </div>
            <h1 className="title">Flexible Working Conditions</h1>
            <p className="description">
            We support remote work, allowing you to work from anywhere. We also offer flexible hours to help you maintain a healthy work-life balance.
            </p>
            {/* <div className='spacing'></div>
  <a href="/" className='explore-link'>
    <div className='explore-container'>
      Explore More
      <img className='arrow' src={Arrow} />
    </div>
  </a> */}
          </div>

          <div className="auditkyc">
            <div className="icon-container">
              <img src={elements} className="icon" />
            </div>
            <h1 className="title">Competitive Compensation</h1>
            <p className="description">
            In addition to a competitive salary, we offer stock options, giving you a stake in the success of Baaz Coin. Your hard work will be rewarded as Baaz Coin grows.
            </p>
            {/* <div className='spacing'></div>
  <a href="/" className='explore-link'>
    <div className='explore-container'>
      Explore More
      <img className='arrow' src={Arrow} />
    </div>
  </a> */}
          </div>
        </Audits>

        <Auditss>
          <div></div>
          <div className="auditkyc">
            <div className="icon-container">
              <img src={customer} className="icon" />
            </div>
            <h1 className="title">Collaborative Team Environment</h1>
            <p className="description">
            At Baaz Coin, we believe in transparency and open communication. You’ll work closely with our founder & CEO where your ideas are valued and your contributions make a real impact.
            </p>
            {/* <div className='spacing'></div>
  <a href="/" className='explore-link'>
    <div className='explore-container'>
      Explore More
      <img className='arrow' src={Arrow} />
    </div>
  </a> */}
          </div>

          <div className="auditkyc">
            <div className="icon-container">
              <img src={bitcoin} className="icon" />
            </div>
            <h1 className="title">Global Reach</h1>
            <p className="description">
            Our project is designed to have a lasting global impact. Whether you’re working on blockchain development, AI systems, or smart contracts, the work you do here will shape the future of decentralization and digital payments.
            </p>
            {/* <div className='spacing'></div>
  <a href="/" className='explore-link'>
    <div className='explore-container'>
      Explore More
      <img className='arrow' src={Arrow} />
    </div>
  </a> */}
          </div>

   
        </Auditss>
      </MainWrapper>
    </div>
  );
};

export default BlockThree;
