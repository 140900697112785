import React from 'react';
import styled from 'styled-components';

const TermsWrapper = styled.div`
  margin: 50px auto;
  max-width: 90%;
  padding: 20px;
  color: #fff;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffba00;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Section = styled.div`
  margin-bottom: 25px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  margin-left:40px;
  line-height: 1.6;
  color: #ccc;

  strong {
    color: #ffba00;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TermsOfUse: React.FC = () => {
  return (
    <TermsWrapper>
      <Paragraph>
        <strong>Last Updated:</strong> December 4, 2024
      </Paragraph>
      <Paragraph>
        Welcome to Baaz Coin. These Terms of Use ("<strong>Terms</strong>") govern your access to and use of our
        services, including the Baaz Coin blockchain, website, and any related products or services provided by Baaz
        Foundation LLC ("<strong>we</strong>", "<strong>us</strong>", or "<strong>our</strong>"). By using our services, you
        agree to these Terms. If you do not agree, please refrain from using our services.
      </Paragraph>
      <Section style={{marginTop:30}}>
        <SectionTitle>1. Eligibility</SectionTitle>
        <Paragraph>
          You must meet the following criteria to use our services:
          <br />
          <strong>1.1.</strong> Be at least 18 years old or the age of majority in your jurisdiction.
          <br />
          <strong>1.2.</strong> Have the legal capacity to enter into binding agreements.
          <br />
          <strong>1.3.</strong> Comply with all applicable laws and regulations, including those related to cryptocurrencies
          and blockchain technology.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>2. Description of Services</SectionTitle>
        <Paragraph>
          Baaz Coin offers a blockchain ecosystem for secure and decentralized digital transactions. The services
          include:
          <br />
          <strong>2.1.</strong> Access to the Baaz Coin blockchain for transactions and smart contracts.
          <br />
          <strong>2.2.</strong> Tools for interacting with the ecosystem, such as wallets and APIs.
          <br />
          <strong>2.3.</strong> Additional features and updates as announced from time to time.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>3. User Responsibilities</SectionTitle>
        <Paragraph>
          <strong>3.1. Account Security:</strong> You are responsible for maintaining the confidentiality of your private
          keys, wallet credentials, and account details. We are not liable for unauthorized access or loss of funds due
          to your negligence.
          <br />
          <strong>3.2. Prohibited Activities:</strong> You agree not to:
          <ul>
            <li>Use the services for illegal purposes, including money laundering or fraud.</li>
            <li>Attempt to disrupt or compromise the integrity of the Baaz Coin blockchain or platform.</li>
            <li>Exploit vulnerabilities or reverse-engineer our systems.</li>
          </ul>
          <strong>3.3. Compliance with Laws:</strong> You must ensure that your use of Baaz Coin complies with all
          applicable laws, including those concerning cryptocurrency and taxation in your jurisdiction.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>4. Transactions and Fees</SectionTitle>
        <Paragraph>
          <strong>4.1. Transaction Irreversibility:</strong> All transactions on the Baaz Coin blockchain are final and
          cannot be reversed once confirmed.
          <br />
          <strong>4.2. Fees:</strong> You agree to pay all applicable transaction fees, which are subject to change at our
          discretion.
        </Paragraph>
      </Section>
      <Section>
  <SectionTitle>5. Intellectual Property</SectionTitle>
  <Paragraph>
    <strong>5.1. Ownership:</strong> All intellectual property rights, including trademarks, logos, and content associated with Baaz Coin, are owned by Baaz Foundation LLC.
    <br />
    <strong>5.2. Restrictions:</strong> You may not reproduce, modify, or distribute our intellectual property without prior written consent.
  </Paragraph>
</Section>

<Section>
  <SectionTitle>6. Risks of Using Blockchain Technology</SectionTitle>
  <Paragraph>
    <strong>6.1. Market Volatility:</strong> Cryptocurrency values are highly volatile and can lead to significant financial loss.
    <br />
    <strong>6.2. Technology Risks:</strong> Blockchain technology is experimental and subject to malfunctions, hacking, and other vulnerabilities.
    <br />
    <strong>6.3. Regulatory Risks:</strong> The legal status of cryptocurrencies varies by jurisdiction and may affect your ability to use Baaz Coin.
  </Paragraph>
</Section>

<Section>
  <SectionTitle>7. Limitation of Liability</SectionTitle>
  <Paragraph>
    <strong>7.1. Liability Disclaimer:</strong> To the fullest extent permitted by law, Baaz Foundation LLC is not liable for any direct, indirect, or consequential damages arising from your use of our services.
    <br />
    <strong>7.2. No Guarantees:</strong> We do not guarantee uninterrupted or error-free operation of our blockchain or platform.
  </Paragraph>
</Section>

<Section>
  <SectionTitle>8. Indemnification</SectionTitle>
  <Paragraph>
    You agree to indemnify and hold harmless Baaz Foundation LLC and its affiliates, officers, and employees from any claims, damages, or losses resulting from your use of our services or violation of these Terms.
  </Paragraph>
</Section>

<Section>
  <SectionTitle>9. Termination</SectionTitle>
  <Paragraph>
    We reserve the right to suspend or terminate your access to our services at any time for any reason, including violations of these Terms or applicable laws.
  </Paragraph>
</Section>

<Section>
  <SectionTitle>10. Modifications to the Terms</SectionTitle>
  <Paragraph>
    We may revise these Terms at any time by posting updates on our website. Your continued use of our services constitutes acceptance of the updated Terms.
  </Paragraph>
</Section>


<Section>
  <SectionTitle>11. Governing Law and Dispute Resolution</SectionTitle>
  <Paragraph>
    <strong>11.1.</strong> To the fullest extent permitted by law, Baaz Foundation LLC is not liable for any direct, indirect, or consequential damages arising from your use of our services.
    <br />
    <strong>11.2.</strong>  Any disputes arising under these Terms shall be resolved through arbitration in St. Petersburg, Florida, in accordance with the rules of the American Arbitration Association.
  </Paragraph>
</Section>

      <Section>
        <SectionTitle>12. Contact Information</SectionTitle>
        <Paragraph>
          If you have any questions about these Terms, please contact us at:
          <br />
          <strong>Baaz Foundation LLC</strong>
          <br />
          7901 4th St N, Ste 300,
          <br />
          St. Petersburg, FL, US 33702
          <br />
          Email: <a href="mailto:contact@baazcoin.com">contact@baazcoin.com</a>
        </Paragraph>
      </Section>
    </TermsWrapper>
  );
};

export default TermsOfUse;
