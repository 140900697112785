import React from 'react';
import styled from 'styled-components';

const TermsWrapper = styled.div`
  margin: 50px auto;
  max-width: 90%;
  padding: 20px;
  color: #fff;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffba00;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Section = styled.div`
  margin-bottom: 25px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  margin-left: 40px;
  line-height: 1.6;
  color: #ccc;

  strong {
    color: #ffba00;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const CookiePolicy: React.FC = () => {
  return (
    <TermsWrapper>
      <Paragraph>
        <strong>Last Updated:</strong> December 2, 2024
      </Paragraph>
      <Paragraph>
        Baaz Foundation LLC ("we," "our," or "us") uses cookies and similar technologies on our website and services
        to enhance user experience, improve functionality, and gather information about how visitors interact with our
        platform. This Cookie Policy explains what cookies are, how we use them, and your choices regarding their use.<br/>
        <br/> <strong>Baaz Foundation LLC</strong>   is located at:
        <br />
        7901 4th St N, Ste 300, St. Petersburg, FL, US 33702.<br/><br/>
      </Paragraph>
      <Section>
        <SectionTitle>1. What Are Cookies?</SectionTitle>
        <Paragraph>
          Cookies are small text files stored on your device (computer, smartphone, or tablet) by websites you visit.
          They are widely used to make websites work efficiently, as well as to provide reporting information.
        </Paragraph><br/>
        <strong style={{color:'#ffba00',fontSize:20}}>1.1. Types of Cookies</strong>
        <Paragraph>
        <br/>     <strong>Essential Cookies:</strong> Required for the website to function properly (e.g., enabling secure
          login).
          <br />
          <strong>Performance Cookies:</strong> Collect anonymous data on how visitors use the website to improve its
          performance.
          <br />
          <strong>Functionality Cookies:</strong> Allow the website to remember your preferences, such as language or
          region.
          <br />
          <strong>Advertising Cookies:</strong> Track your online activity to provide personalized advertising.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>2. How We Use Cookies</SectionTitle>
        <Paragraph>
          We use cookies to:
          <br />
          <strong>2.1.</strong> Provide core functionality, such as enabling secure transactions.
          <br />
          <strong>2.2.</strong> Analyze website usage to improve performance and user experience.
          <br />
          <strong>2.3.</strong> Remember your preferences and enhance personalization.
          <br />
          <strong>2.4.</strong> Deliver relevant advertisements based on your interests and browsing history.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>3. Cookies We Use</SectionTitle>
        <Paragraph>
        We use cookies to:
          <br />
          <strong>3.1. Essential Cookies :</strong> These cookies enable core functions, such as accessing secure areas and processing transactions.

          <br />
          <strong>3.2. Analytical/Performance Cookies
 :</strong> Used to collect data about website traffic and user behavior. For example, we may use Google Analytics to track page views and clicks.

          <br />
          <strong>3.3. Functionality Cookies
:</strong> Help remember your preferences, such as language settings or saved wallets.
          <br />
          <strong>3.4. Third-Party Cookies :</strong> Some cookies may be set by third-party services, such as social media platforms or advertising networks.

        </Paragraph>
     
      </Section>
      <Section>
        <SectionTitle>4. Managing Cookies</SectionTitle>
        <Paragraph>
          You have the right to manage your cookie preferences.
        </Paragraph><br/>
        <Paragraph><strong>4.1. Browser Settings</strong>
        <Paragraph>
          Most web browsers allow you to:
          <br />
          - Delete cookies.
          <br />
          - Block cookies.
          <br />
          - Receive notifications before cookies are placed.
          <br />
          Check your browser’s help section for specific instructions.
        </Paragraph>
        </Paragraph>

 
     <br/>   <Paragraph><strong>4.2. Opting Out of Analytics :
</strong>         If you do not want to be tracked by analytics tools like Google Analytics, you can use tools like the<a style={{color:'#ffba00'}} href="https://tools.google.com/dlpage/gaoptout"> Google Analytics Opt-out Browser Add-on</a>.
</Paragraph>
    
<br/>
<Paragraph><strong>4.3. Advertising Preferences</strong>
<Paragraph>
          You can manage your online advertising preferences using tools such as:
          <br />
          - Network Advertising Initiative (NAI)
          <br />
          - Digital Advertising Alliance (DAA)
        </Paragraph>
        </Paragraph>

       
      </Section>
      <Section>
        <SectionTitle>5. Changes to This Cookie Policy</SectionTitle>
        <Paragraph>
          We may update this Cookie Policy to reflect changes in technology, law, or our practices. Updates will be
          posted on our website with the "Last Updated" date.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>6. Contact Us</SectionTitle>
        <Paragraph>
          If you have questions about this Cookie Policy or our use of cookies, please contact us at:
          <br /><br/>
         <strong> Baaz Foundation LLC</strong>
          <br />
          7901 4th St N, Ste 300,
          <br />
          St. Petersburg, FL, US 33702
          <br /><br/>
          Email: <a href="mailto:contact@baazcoin.com">contact@baazcoin.com</a><br/><br/>
          By continuing to use our website and services, you agree to the use of cookies as described in this Cookie Policy.

        </Paragraph>
      </Section>
    </TermsWrapper>
  );
};

export default CookiePolicy;
