import React, { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';
import { useSnackbar, SnackbarProvider } from "notistack";


import icon from '../../assets/images/Icon.png'
import copy from '../../assets/images/fi-br-copy.png'
import qr from '../../assets/images/qr-code.png'
import whitepaper from '../../assets/images/whitepaper.png'
import audit from '../../assets/images/audit-01.png'
import kyc from '../../assets/images/kyc-1.png'
import Tokenomics from './Tokenomics'



const MainWrapper = styled.div`
margin: 50px 0px;
max-width:100%;
border-radius:20px;
padding:20px;
grid-template-columns:20% 80%;


p{
  text-align:center;
  font-size:17px;
  color:#ccc;
  margin:0 30%;

}

h1{
  text-align:center;
  color:#fff;
  font-weight:500;
  font-size:45px;
  font-family: var(--font-bold);
  @media (max-width: 768px) {
    font-size:22px;
   
   }
}


`;


const AuditSheild = styled.div`
padding:0px 80px;
margin-top:30px;
@media (max-width: 768px) {
  padding:0px 0px;

}

.token-alloc{
  display:grid;
  grid-template-columns:65% auto;
  @media (max-width: 768px) {
    grid-template-columns:1fr ;

  }
}

.alloc-num{
  display:grid;
  margin-top:40px;
  gap:20px;
  column-gap:30px;
  grid-template-columns:1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns:1fr ;

  }
}
`;

const AuditSheilds = styled.div`
background-color:#101010;
padding:30px;
margin-top:30px;
border-radius:20px;

.buttons{
  display:grid;
  margin-top:40px;
  gap:10px;
  grid-template-columns:1fr 1fr 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns:1fr ;

  }
}

button div{
  display:flex;
  justify-content:space-between;
align-items:center;
}

button{
  background:black;
  color:white;
  border:none;
  border-radius:10px;
  padding:15px 20px;
  font-size:20px;
}
`;
const Audits = styled.div`
display:grid;
margin-top:0px;
background-color:#101010;
width:100%;
grid-template-columns:1fr 1fr 1fr;
gap:40px;
@media (max-width: 768px) {
  grid-template-columns:1fr;
  gap:20px;

}

.auditkyc{
  background-color:#262626;
  border-radius:15px;
  padding:15px 25px;
}


img{
  margin-bottom:20px;
  width:160px;
  height:160px;
}

`;



const AuditDetails = styled.div`
padding:30px;
width:100%;
margin-top:20px;
border-radius:20px;
background-color:black;

p{
  font-size:14px;
}

`;


const Icon = styled.div`
display:flex;
justify-content:center;
align-items:center;
gap:20px;
margin-top:20px;

svg{
  width:50px;
  height:50px;
}

img{
  width:50px;
  height:50px;
}


@media (max-width: 768px) {
  display:grid;
  grid-template-columns:1fr 1fr 1fr;
  gap:10px;
  svg{
    width:40px;
    height:40px;
  }
  
  img{
    width:40px;
    height:40px;
  }
}
`;


const BlockThree: React.FC = () => {
  
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = () => {
    const textToCopy = "0xeD6797a4f0F490AeD75264F0bA3Df95B42DB4704";
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        enqueueSnackbar("Copied to clipboard!", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Failed to copy!", { variant: "error" });
      });
  };

  return (
    <div className="blockthree-wrapper " style={{margin:0}}>
       <MainWrapper>
<h1><span style={{color:'#ffba00'}}> Tokenomics </span></h1>
<AuditSheild>
<AuditSheilds>
  <div style={{padding:'0px 10px 15px'}}>
Contract<br/>
<strong style={{fontSize:15,fontFamily:'var(--font-medium)',wordBreak:'break-word'}}>0xeD6797a4f0F490AeD75264F0bA3Df95B42DB4704
<img style={{width:14,height:14,marginLeft:5,cursor:'pointer'}}         onClick={handleCopy}
 src={copy}/></strong>
</div>
<Audits>
  
<div className='auditkyc'>
<div style={{width:'fit-content',display:'flex',borderRadius:10,justifyContent:'center',alignItems:'center'}}><img src={icon} style={{width:40,height:40,margin:0}}/></div>
<p style={{padding:0,margin:0,textAlign:'left',fontSize:16,marginTop:10}}>TOTAL SUPPLY</p>

<h1 style={{fontSize:26,fontWeight:600,fontFamily:'var(--font-normal)',textAlign:'left',marginTop:10}}>50,000,000</h1>
</div>

<div className='auditkyc'>
<div style={{width:'fit-content',display:'flex',borderRadius:10,justifyContent:'center',alignItems:'center'}}><img src={icon} style={{width:40,height:40,margin:0}}/></div>
<p style={{padding:0,margin:0,textAlign:'left',fontSize:16,marginTop:10}}>DECIMAL</p>

<h1 style={{fontSize:26,fontWeight:600,fontFamily:'var(--font-normal)',textAlign:'left',marginTop:10}}>18</h1>
</div>

<div className='auditkyc'>
<div style={{width:'fit-content',display:'flex',borderRadius:10,justifyContent:'center',alignItems:'center'}}><img src={icon} style={{width:40,height:40,margin:0}}/></div>
<p style={{padding:0,margin:0,textAlign:'left',fontSize:16,marginTop:10}}>SYMBOL</p>

<h1 style={{fontSize:26,fontWeight:600,fontFamily:'var(--font-normal)',textAlign:'left',marginTop:10}}>BAAZ</h1>
</div>



</Audits>
<div className='buttons'>
  <button  style={{cursor:'pointer'}} onClick={() => window.location.href = "https://baazscan.com"}
>
    <div >
    BAAZSCAN
    <img src={qr}/>
    </div>
  </button>

  <button style={{cursor:'pointer'}}  onClick={() => window.location.href = "https://github.com/expelee-co/Smart-Contract-Audit/blob/main/Baaz%20Coin%20Expelee%20Audit.pdf"}
>
    <div >
    AUDIT
    <img src={audit}/>
    </div>
  </button>

  <button>
    <div >
    KYC
    <img src={kyc}/>
    </div>
  </button>

  <button style={{cursor:'pointer'}}  onClick={() => window.location.href = "https://docs.baazcoin.com"}
>
    <div >
    WHITEPAPER
    <img src={whitepaper}/>
    </div>
  </button>
</div>
</AuditSheilds>

<AuditSheilds>
<div className='token-alloc'>

  <div className='allocation'>
    <h2 style={{textAlign:'center',color:'#ffba00'}}>Baaz Coin Allocation</h2>
    <div className='alloc-num'>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        Presale :
        <span style={{color:'#f8e19c'}}>15,000,000</span>
      </div>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        IEO :
        <span style={{color:'#f8e19c'}}>2,500,000</span>
      </div>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        Private Sale :
        <span style={{color:'#f8e19c'}}>15,000,000</span>
      </div>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        Airdrop :
        <span style={{color:'#f8e19c'}}>1,250,000</span>
      </div>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        Advisor :
        <span style={{color:'#f8e19c'}}>2,500,000</span>
      </div>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        Staking :
        <span style={{color:'#f8e19c'}}>2,500,000</span>
      </div>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        Validator :
        <span style={{color:'#f8e19c'}}>2,500,000</span>
      </div>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      Founder & Team :
        <span style={{color:'#f8e19c'}}>2,500,000</span>
      </div>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        Blockchain Development :
        <span style={{color:'#f8e19c'}}>5,000,000</span>
      </div>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      Data Provider Reward :
        <span style={{color:'#f8e19c'}}>1,250,000</span>
      </div>
    </div>
    </div>
    <div className=" " style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Tokenomics/>
    </div></div>

</AuditSheilds>



</AuditSheild>
       </MainWrapper>
    </div>
  );
};

export default BlockThree;
