import React from 'react';
import styled from 'styled-components';

import customer from '../../assets/images/customer-support.png';
import elements from '../../assets/images/elements.png';
import bitcoin from '../../assets/images/bitcoin-credit-card.png';
import crown from '../../assets/images/crown.png';

const MainWrapper = styled.div`
  margin: 50px 0px;
  max-width: 100%;
  border-radius: 20px;
  padding: 20px;

  h1 {
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 45px;
    font-family: var(--font-bold);
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  p {
    text-align: center;
    font-size: 17px;
    color: #ccc;
    margin: 0 30%;

    @media (max-width: 768px) {
      margin: 0;
      font-size: 13px;
    }
  }
`;

const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  padding: 20px 200px;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px 0px;
  }

  .auditkyc {
    background-color: #262626b0;
    ;
    border-radius: 15px;
    border: 1px solid #606060;
    padding: 35px;
    @media (max-width: 768px) {
      padding: 25px;

    }

    .icon-container {
      width: fit-content;
      padding: 7px;
      display: flex;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      background-color: #303030;
      border: 1px solid #606060;
    }

    img {
      width: 40px;
      height: 40px;
      margin: 0;
      @media (max-width: 768px) {
        width: 30px;
        height: 30px;      }
    }

    h1 {
      font-size: 26px;
      font-weight: 600;
      font-family: var(--font-normal);
      text-align: left;
      margin-top: 10px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    p {
      padding: 0;
      margin: 0;
      text-align: left;
      font-size: 14px;
      margin-top: 10px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`;

const BlockThree: React.FC = () => {
  const auditData = [
    {
      image: customer,
      title: "Be Part of a Revolution",
      description:
        "At Baaz, we’re creating an entirely new financial and technological ecosystem. Our project is not just a token, but a full-fledged blockchain system that will be at the heart of decentralized applications (dApps), digital payments, DeFi protocols, and more. When you work with us, you’re at the forefront of a technological revolution that will shape the future of decentralization.",
    },
    {
      image: bitcoin,
      title: "Cutting-Edge Technology",
      description:
        "We are constantly pushing the boundaries of what’s possible with blockchain. From the launch of our Layer 1 Baaz Chain to the migration to Layer 2 solutions for faster and more efficient transactions, to the integration of AI-powered features like facial recognition and voice recognition—Baaz Coin is a project that thrives on innovation.",
    },
    {
      image: elements,
      title: "Transparency and Collaboration",
      description:
        "Unlike many projects, we pride ourselves on our transparency. Our founder, CEO, and entire team are fully open and accessible. We believe in collaboration and are committed to creating a work environment where everyone’s voice matters. When you join Baaz, you join a supportive, dynamic team that values your ideas and contributions.",
    },
    {
      image: crown,
      title: "Global Impact",
      description:
        "Baaz Coin is designed to make a real difference in the world. Our decentralized payment solutions will revolutionize industries, making it easier than ever for people to transact across borders. Whether you’re working on our blockchain development or our decentralized exchange (DEX), the work you do here will have a lasting impact on the global economy.",
    },
  ];

  return (
    <div className="blockthree-wrapper apoplo">
      <MainWrapper>
        <h1>
          Why Work at <span style={{ color: '#ffba00' }}>Baaz Foundation</span>
        </h1>
        <p>
        Fully AI-Powered POS – Designed for the Future of Customer Service and Digital Payments
        </p>
        <Audits>
          {auditData.map((audit, index) => (
            <div className="auditkyc" key={index}>
         
              <h1>{audit.title}</h1>
              <p>{audit.description}</p>
            </div>
          ))}
        </Audits>
      </MainWrapper>
    </div>
  );
};

export default BlockThree;
