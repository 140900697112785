import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { EthereumClient } from "@web3modal/ethereum";
import "./index.scss";
import App from "./App";
import { WagmiConfig } from "wagmi";
import { Web3Modal } from "@web3modal/react";
import { chains, walletConnectProjectId, config } from "./wagmi";

const ethereumClient = new EthereumClient(config, chains);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiConfig config={config}>
        <App />
        <Web3Modal
        themeVariables={{
          "--w3m-accent-color": "#ffba00",
          "--w3m-accent-fill-color": "#000",

          "--w3m-button-border-radius": "32px",
          "--w3m-text-medium-regular-size": "14px",
          "--w3m-wallet-icon-border-radius": "5px",
        }}
        projectId={walletConnectProjectId}
        ethereumClient={ethereumClient}
      />      </WagmiConfig>
    </BrowserRouter>
  </React.StrictMode>
);

