import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import weconnect from "../../assets/images/weconnect.png";
import cgt from "../../assets/images/cgt.png";
import blockscout from "../../assets/images/blockscoit.svg";
import orderrlo from "../../assets/images/Orrderlo.png";

const MainWrapper = styled.div`
  margin: 50px 0;
  max-width: 100%;
  border-radius: 20px;
  padding: 20px;
  p {
    text-align: center;
    font-size: 17px;
    color: #ccc;
    margin: 0 30%;
    @media (max-width: 768px) {
      margin: 0 0%;
      font-size: 14px;
      margin-top: 10px;
    }
  }
  h1 {
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 45px;
    font-family: var(--font-bold);
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
`;

const PartnerCard = styled.div`
  background: #262626;  /* Initial solid background */
  border-radius: 10px;
  text-align: center;
  padding: 40px;
  height: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: background 0.5s ease-in-out;  /* Set transition duration to 2 seconds */

  &:hover {
    background: linear-gradient(to bottom, #ffba00, #262626);  /* Apply gradient on hover */
  }

  img {
    margin: 20px 0 40px 0;
  }
  h3 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 10px;
  }
  p {
    color: #ccc;
    font-size: 15px;
    margin: 0;
    margin-bottom: 15px;
  }
  button {
    background-color: #ffba00;
    border: none;
    color: #000;
    margin-top: 20px;
    padding: 15px 25px;
    border-radius: 500px;
    cursor: pointer;
    font-family: var(--font-medium);
    font-size: 16px;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #e5a700;
    }
  }
`;



const SliderWrapper = styled.div`
  margin-top: 30px;

  .slick-slide {
    padding: 0 60px;
    @media (max-width: 768px) {
      padding: 0 0px;
    }
  }

  .slick-dots {
    bottom: -35px;
  }

  .slick-dots li button:before {
    font-size: 12px;
    color: #fff;
  }

  .slick-dots li.slick-active button:before {
    color: #ffba00;
  }
`;

const partners = [
  {
    id: 1,
    logo: weconnect,
    description:
      "WECONN3CT delivers tailored solutions to meet the unique needs of companies and candidates.",
    imageWidth: 280,
    imageHeight: 50,
    link: '/',

  },
  {
    id: 2,
    logo: orderrlo,
    description: "Orrderlo® provides custom POS Kassensystem solutions.",
    imageWidth: 160,
    imageHeight: 100,
    link: 'https://orrderlo.com/de/auth',

  },
  {
    id: 3,
    logo: blockscout,
    description:
      "Blockscout Open-source, universally accessible, and fully customizable portal is your definitive gateway to the blockchain world.",
    imageWidth: 250,
    imageHeight: 70,
    link: 'https://www.blockscout.com/',

  },
  {
    id: 4,
    logo: cgt,
    description:
      "Coingabbar delivers trusted crypto news, insights, and events, reaching millions monthly.",
    imageWidth: 100,
    imageHeight: 100,
    link: 'https://www.coingabbar.com/en/crypto-ico-details/baaz-coin-upcoming-ico-list-2024-blockchan-wallet',

  },
];

const BlockTen: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="blockthree-wrapper" style={{ margin: 0 }}>
      <MainWrapper>
        <h1>
          <span style={{ color: "#ffba00" }}>Baaz</span> Partners
        </h1>
        
        <SliderWrapper>
          <Slider {...settings}>
            {partners.map((partner) => (
              <PartnerCard key={partner.id}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={partner.logo}
                    style={{
                      width: `${partner.imageWidth}px`,
                      height: `${partner.imageHeight}px`,
                    }}
                    alt="Partner logo"
                  />
                </div>
                <p>{partner.description}</p>
                <button  onClick={() => window.location.href = `${partner.link}`}>Get Started</button>
              </PartnerCard>
            ))}
          </Slider>
        </SliderWrapper>
      </MainWrapper>
    </div>
  );
};

export default BlockTen;
