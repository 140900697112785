import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';
import TeamSection from './TeamSection';

import customer from '../../assets/images/1.png'
import elements from '../../assets/images/3.png'
import bitcoin from '../../assets/images/2.png'
import crown from '../../assets/images/crown.png'
import BenBack from '../../assets/images/ben-back.png'
import Arrow from '../../assets/images/arrow-right-01.png'

const MainWrapper = styled.div`
margin: 50px 0px;
max-width:100%;
border-radius:20px;
padding:20px;
grid-template-columns:20% 80%;


p{
  text-align:center;
  font-size:17px;
  color:#ccc;
  margin:0 30%;
  @media (max-width: 768px) {
    margin:0 0%;
    font-size:14px;
    margin-top:10px;

  }
}

h1{
  text-align:center;
  color:#fff;
  font-weight:500;
  font-size:45px;
  font-family: var(--font-bold);
  @media (max-width: 768px) {
    font-size:22px;
   
   }
}


`;


const Audits = styled.div`
display:grid;
margin-top:20px;
width:100%;
padding:20px 200px;
grid-template-columns:1fr 1fr 1fr;
gap:40px;
@media (max-width: 768px) {
  grid-template-columns:1fr;
  row-gap:20px;

}

.auditkyc{
  border-radius:15px;
  background-image: url(${BenBack});
  background-size: contain;
  background-repeat: no-repeat;

  padding:35px;
}


img{
  margin-bottom:20px;
  width:160px;
  height:160px;
}

`;



const AuditDetails = styled.div`
padding:30px;
width:100%;
margin-top:20px;
border-radius:20px;
background-color:black;

p{
  font-size:14px;
}

`;


const Icon = styled.div`
display:flex;
justify-content:center;
align-items:center;
gap:20px;
margin-top:20px;

svg{
  width:50px;
  height:50px;
}

img{
  width:50px;
  height:50px;
}


@media (max-width: 768px) {
  display:grid;
  grid-template-columns:1fr 1fr 1fr;
  gap:10px;
  svg{
    width:40px;
    height:40px;
  }
  
  img{
    width:40px;
    height:40px;
  }
}
`;


const BlockThree: React.FC = () => {
  
  return (
    <div className="blockthree-wrapper " style={{margin:0}}>
       <MainWrapper>
<h1>Our <span style={{color:'#ffba00'}}>Team</span></h1>
<p> BUILDING THE FUTURE OF DECENTRALIZATION WITH INNOVATION AND EXPERTISE.</p>

<TeamSection/>

       </MainWrapper>
    </div>
  );
};

export default BlockThree;
