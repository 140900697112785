import React, { useState } from "react";
import styled from "styled-components";
import Plus from "../../assets/images/plus-circle.png"
import Minus from "../../assets/images/minus-circle.png"
import FaqBg from "../../assets/images/faq-bg.png"
// Styled components
const MainWrapper = styled.div`
  margin: 50px 0;
  max-width: 100%;
  padding: 20px;
  padding-top: 100px;
  border-radius: 20px;
  background-image: 
    linear-gradient(to top, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 1)),
    url(${FaqBg});
  background-position: center;
  background-repeat: no-repeat;
  );
  background-attachment: fixed;
  background-size: 100%; 100% /* Fix background size */
    transition: background-position 0.6s ease;

  &.scroll-bg {
    background-position: top;
  }

  h1 {
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 45px;
    font-family: var(--font-bold);
    margin-bottom: 20px;
    span {
      color: #ffba00;
    }
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  p {
    text-align: center;
    font-size: 16px;
    color: #ccc;
    margin: 0 0 40px;
    @media (max-width: 768px) {
      margin: 0 0% 40px;
      font-size: 14px;
      margin-top: 10px;
    }
  }

  .faq-item {
    border-bottom: 1px solid #464646;
    margin-bottom: 15px;
    padding: 20px;
    transition: all 0.3s ease-in-out;
  }

  .faq-main {
    padding: 0px 130px;
    @media (max-width: 768px) {
      padding: 0px 0px;
    }
  }

  .faq-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .faq-content {
    color: #ccc;
    font-size: 16px;
    margin-top: 0px;
    padding-left: 15px;
    padding-right: 15px;
    transition: all 0.3s ease-in-out;
    max-height: 0;
    overflow: hidden;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .faq-content.open {
    max-height: 500px; /* Adjust based on expected content size */
    margin-top: 10px;
  }

  .icon {
    font-size: 20px;
    transition: transform 0.3s ease-in-out;
  }

  .icon.open {
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 22px;
    }
    .faq-item {
      padding: 15px;
    }
    .faq-content {
      font-size: 14px;
    }
  }
`;


const FAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleContent = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    
      {
          question: "What is Baaz Coin?",
          answer: "Baaz Coin is a cryptocurrency designed to support a decentralized ecosystem with enhanced scalability, fast transactions, and innovative blockchain solutions."
      },
      // {
      //     question: "Who is the founder of Baaz Coin?",
      //     answer: "Baaz Coin was founded by Nirvair, the CEO of Baaz Foundation LLC."
      // },
      {
          question: "What is the vision behind Baaz Coin?",
          answer: "The vision of Baaz Coin is to revolutionize digital payments, create secure blockchain ecosystems, and support real-world applications like POS systems for businesses."
      },
      {
          question: "What consensus mechanism does Baaz Coin use, and how does it ensure scalability?",
          answer: "Baaz Coin currently operates on a Layer 1 blockchain using a Proof-of-Stake (PoS) consensus mechanism. This ensures high transaction throughput, energy efficiency, and security at the base layer."
      },
      {
          question: "Is Baaz Coin working on Layer 2 solutions?",
          answer: "Yes, Baaz Coin is actively developing Layer 2 solutions to enhance scalability and efficiency. These solutions will include advanced features such as facial recognition and voice recognition, aimed at providing a seamless and secure user experience for transactions and other blockchain interactions."
      },
      {
          question: "How does Baaz Coin ensure transaction speed and low fees on Layer 1?",
          answer: "The Baaz Coin Layer 1 blockchain is optimized with:\n• Efficient Consensus: PoS reduces computational overhead.\n• Network Optimization: Streamlined block validation processes ensure high throughput.\n• Dynamic Fee Adjustment: Transaction fees are adjusted based on network usage to prevent congestion."
      },
      {
          question: "What are the future benefits of Layer 2 integration for Baaz Coin?",
          answer: "The upcoming Layer 2 technology will provide:\n• Facial and Voice Recognition Integration: Enhancing security and ease of access.\n• Faster Transactions: Offloading computations to Layer 2 will significantly reduce latency.\n• Cost Efficiency: Users will enjoy lower transaction fees for micro-transactions and high-frequency trading.\n• Broader Usability: Advanced features will allow integration into more real-world applications, such as AI-powered POS systems and digital wallets."
      },
      {
          question: "How does Baaz Coin protect user data on the blockchain?",
          answer: "On Layer 1, Baaz Coin employs:\n• Encryption Protocols: All data is encrypted to ensure privacy.\n• Transaction Anonymity: While transactions are transparent, sensitive details remain protected.\nFor Layer 2 (in development), facial and voice recognition will be implemented using secure machine learning algorithms to prevent identity theft or unauthorized access."
      },
      // {
      //     question: "What is the role of staking in the Baaz Coin ecosystem?",
      //     answer: "Staking in Baaz Coin:\n• Secures the Network: Validators stake Baaz Coins to validate transactions and secure the blockchain.\n• Incentivizes Participation: Stakers earn rewards for their contributions, which are dynamically adjusted based on the network's performance and demand.\n• Governance: Stakers can participate in on-chain governance to propose and vote on ecosystem updates."
      // },
      {
          question: "How does Baaz Coin protect user data on the blockchain?",
          answer: "Baaz Coin implements zero-knowledge proofs (ZKPs) and encryption protocols to anonymize sensitive transaction data while ensuring full compliance with transparency and auditability requirements."
      },
      // {
      //     question: "What measures are in place to prevent a 51% attack?",
      //     answer: "• Economic Disincentives: Gaining control over 51% of staked Baaz Coins would require an enormous financial investment.\n• Layered Security: Multi-tier verification and off-chain monitoring systems identify and mitigate suspicious activities.\n• Governance Oversight: Community governance prevents malicious actors from compromising the ecosystem."
      // },
      // {
      //     question: "How does the dispute resolution feature in BaazPay work?",
      //     answer: "BaazPay integrates smart contract-based escrow systems. In case of disputes:\n• Funds are held in escrow until the issue is resolved.\n• Both parties provide evidence, and an automated arbitration protocol evaluates the inputs.\n• The system resolves disputes transparently using a decentralized arbitration mechanism or a manual override by the Baaz Foundation when needed."
      // },
      {
          question: "What makes Baaz Coin unique compared to other cryptocurrencies?",
          answer: "• Real-World Integration: Baaz Coin powers innovative products like AI-powered POS systems and BaazPay for seamless digital payments.\n• Cross-Chain Trading: The ecosystem will soon support perpetual trading across blockchains.\n• Scalability: Advanced Layer 2 solutions ensure cost-effective and fast transactions.\n• Developer-Friendly: EVM compatibility makes it easy to build and deploy dApps."
      },
      {
          question: "How is Baaz Coin planning to revolutionize the Point-of-Sale (POS) industry?",
          answer: "Baaz Coin’s POS system incorporates:\n• AI for personalized order recommendations and real-time data analytics.\n• QR code-based payments, enabling faster, contactless transactions.\n• Baaz Coin integration, allowing businesses to accept cryptocurrency alongside traditional payment methods."
      },
      {
          question: "How does Baaz Coin ensure ecosystem sustainability?",
          answer: "Baaz Coin’s PoS mechanism is energy-efficient, and staking rewards are optimized to prevent hyperinflation. Additionally, transaction fees are partially burned to create a deflationary pressure on the token supply."
      },
      {
          question: "How does governance work in the Baaz Coin ecosystem?",
          answer: "Baaz Coin governance is on-chain and community-driven:\n• Proposals for ecosystem upgrades are submitted by stakeholders.\n• Votes are weighted based on staked Baaz Coins.\n• Approved proposals are automatically implemented via smart contracts."
      },
      // {
      //     question: "What is the role of community nodes in the Baaz blockchain?",
      //     answer: "Community nodes help decentralize the network by:\n• Validating transactions.\n• Maintaining copies of the blockchain.\n• Voting on governance issues to influence network decisions."
      // },
    
      {
          question: "What is the plan for global adoption of Baaz Coin?",
          answer: "Baaz Coin aims to:\n• Expand partnerships with merchants, especially in gastronomy and retail sectors.\n• Launch educational initiatives to increase cryptocurrency awareness.\n• Collaborate with influencers and industry leaders to promote adoption."
      },
      {
          question: "How will Baaz Coin maintain relevance with emerging technologies?",
          answer: "Baaz Coin continuously integrates cutting-edge technologies, such as:\n• AI for transaction analytics and fraud detection.\n• Machine learning for improving smart contract functionality.\n• Decentralized identity management (DID) to enhance user security."
      }
  
  
  ];
  const formatAnswer = (answer: string) => {
    return answer.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  return (
    <MainWrapper>
      <h1>
        Frequently Asked <span>Questions</span>
      </h1>
      <p>
        If you can't find an answer to your question, contact us at{" "}
        <a href="mailto:support@baazcoin.com" style={{ color: "#ffba00" }}>
          support@baazcoin.com
        </a>
      </p>
      <div className="faq-main">
        {faqData.map((item, index) => (
          <div key={index} className="faq-item" onClick={() => toggleContent(index)}>
            <div className="faq-header">
              <span>{item.question}</span>
              <div
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                className={`icon ${openIndex === index ? "open" : ""}`}
              >
                <img src={openIndex === index ? Minus : Plus} alt="Toggle icon" />
              </div>
            </div>
            <div className={`faq-content ${openIndex === index ? "open" : ""}`}>
              {formatAnswer(item.answer)}
            </div>
          </div>
        ))}
      </div>
    </MainWrapper>
  );
};

export default FAQ;