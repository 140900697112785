import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faGlobe, faWallet } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar, SnackbarProvider } from "notistack";
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons'
import { useNetwork, useSwitchNetwork, useConnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import Logo from "../../assets/images/logo-white.png"
const Container = styled.div`
  padding: 0px;
  margin: 20px auto;

  border-radius: 10px;
`;

const ButtonRow = styled.div`
  display: grid;
  gap: 10px;
  width:100%;
grid-template-columns:1fr 1fr 1fr;  margin-top: 15px;

@media (max-width: 768px) {
  grid-template-columns:1fr}
`;

const ActionButton = styled.button`
  background: rgba(38, 38, 38, 0.6);
  color: white;
  border: 1px solid #606060;
font-family:var(--font-medium);
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background: #555;
    transform: scale(1.02);
  }
`;

const Containers = styled.div`
  padding: 12px;
  margin: 0 auto;
border: 1px solid #606060;
background-color: rgba(38, 38, 38, 0.6);
  border-radius: 10px;
  display:flex;
  gap:20px;
  justify-content:center;
  align-items:center;

  @media (max-width: 768px) {
    flex-direction:column;
  }

  h3{
    font-size:15.5px;
    margin-bottom:3px;

  }

  .andr{
    text-align:center !important;
  }
  p{
    margin:0 !important;
    padding:0 !important;
    text-align:left !important;
    font-size:13px !important;
  }
`;


const StepsList = styled.ol`
  padding-left: 20px;
  padding-top:20px;
  margin-bottom: 30px;

  li {
    font-size: 16px;
    margin-bottom: 13px;
    line-height: 1.5;

    &::marker {
      color: #ffba00; /* Set the marker (index number) color */
      font-family: var(--font-medium);
    }
  }
`;

const Button = styled.button`
  background: linear-gradient(90deg, #ff9800, #ffba00);
  color: white;
  font-weight: bold;
  border: none;
  padding: 15px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  margin-top: 10px;

  &:hover {
    background: linear-gradient(90deg, #e68800, #e0a900);
    transform: scale(1.02);
  }
`;

const HighlightBox = styled.div`
  padding: 15px;
  margin-top: 20px;
  border: 1px solid #606060;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  background-color: rgba(38, 38, 38, 0.6);

  word-break: break-word;
`;

const Image = styled.img`
  width: 60px;
  height: 60px;
  margin: 0px auto 0px;
  display: block;
`;

const HowToBuy: React.FC = () => {
  const tokenAddress = "0xeD6797a4f0F490AeD75264F0bA3Df95B42DB4704";
  const tokenSymbol = "BAAZ";
  const tokenDecimals = 18;
  const tokenImage = Logo;

  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });

  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();




  const { enqueueSnackbar } = useSnackbar();

  const handleAddToken = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              name: "Baaz Coin",
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: tokenImage,
            },
          },
        });
        enqueueSnackbar("Token added to MetaMask!", { variant: "success" });
      } catch (error) {
        enqueueSnackbar("Failed to add token to MetaMask.", { variant: "error" });
        console.error("Error adding token:", error);
      }
    } else {
      alert("MetaMask is not installed.");
    }
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(tokenAddress).then(() => {
      enqueueSnackbar("Token address copied!", { variant: "success" });
    });
  };

  const handleExplorerLink = () => {
    window.open(`https://bscscan.com/token/${tokenAddress}`, "_blank");
  };

  return (
    <Container>
        <Containers>      <Image src={tokenImage} alt="Token Icon" />
        <div>
  <h3>Baaz Coins are sold on the BNB Smart Chain (BEP20)</h3>
  <p>
    You need BNB to purchase Baaz Coin and to pay the network transaction fees. The easiest way to top up your wallet is through Centralized Exchanges.
  </p>
</div>

</Containers>
      <StepsList>
        <li>Connect your wallet by clicking the <span style={{color:'#ffba00',fontFamily:'var(--font-medium)'}}>Connect Wallet</span> button in top right corner of card.</li>
        <li>Ensure your wallet is connected to the <span style={{color:'#ffba00',fontFamily:'var(--font-medium)'}}>Binance Smart Chain (BSC)</span>.</li>
        <li>Enter the amount you want to buy on the presale page.</li>
        <li>Complete the transaction and claim your <span style={{color:'#ffba00',fontFamily:'var(--font-medium)'}}>Baaz Coin</span>.</li>
      </StepsList>
   
    
      <HighlightBox>
        <strong style={{color:'#ffba00'}}>Token Address :</strong> {tokenAddress}
      </HighlightBox>
      <ButtonRow>
      <ActionButton onClick={handleAddToken}>
            <div style={{display:"flex",gap:10,justifyContent:'center',alignItems:'center'}}>         <FontAwesomeIcon icon={faWallet} />
          Add to MetaMask</div>
 
        </ActionButton>
        <ActionButton onClick={handleCopyAddress}>
        <div style={{display:"flex",gap:10,justifyContent:'center',alignItems:'center'}}> <FontAwesomeIcon icon={faCopy} />
          Copy Address</div>

         
        </ActionButton>
        <ActionButton   onClick={() => {handleExplorerLink()
  }}>
        <div style={{display:"flex",gap:10,justifyContent:'center',alignItems:'center'}}>   <FontAwesomeIcon icon={faGlobe} />
          View on Explorer</div>

       
        </ActionButton>
   
      </ButtonRow>

<Containers style={{flexDirection:'column',textAlign:'center',marginTop:15}}>     
        <div>
  <h3>Have any questions?</h3>
  <p className="andr" style={{flexDirection:'column',textAlign:'center'}}>
  If you encounter any issues with the purchase, please contact official support.
  </p>
</div>

<button
  className="button-prim"
  style={{ marginTop: 0, display: 'flex', alignItems: 'center', gap: '8px' }}
  onClick={() => {
    window.open('https://t.me/+KBmfRIGxGe1iZDA6', '_blank'); // Replace with your Telegram group link
  }}
>
<FontAwesomeIcon icon={faTelegramPlane} size="lg" /> {/* Use lg, 2x, etc., for different sizes */}
  BAAZ Support
</button>
</Containers>

    </Container>
  );
};

export default HowToBuy;
