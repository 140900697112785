import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';


import customer from '../../assets/images/1.png'
import elements from '../../assets/images/3.png'
import bitcoin from '../../assets/images/2.png'
import crown from '../../assets/images/crown.png'
import BenBack from '../../assets/images/ben-back.png'
import Arrow from '../../assets/images/arrow-right-01.png'

const MainWrapper = styled.div`
margin: 50px 0px;
max-width:100%;
border-radius:20px;
padding:20px;
grid-template-columns:20% 80%;


p{
  text-align:center;
  font-size:17px;
  color:#ccc;
  margin:0 30%;
  @media (max-width: 768px) {
    margin:0 0%;
    font-size:14px;
    margin-top:10px;

  }
}

h1{
  text-align:center;
  color:#fff;
  font-weight:500;
  font-size:45px;
  font-family: var(--font-bold);
  @media (max-width: 768px) {
    font-size:22px;
   
   }
}


`;


const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  padding: 20px 200px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px 30px;
  }

  .auditkyc {
    border-radius: 15px;
    background-image: url(${BenBack});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 25px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }

    .icon-container {
      width: fit-content;
      display: flex;
      border-radius: 10px;
      justify-content: center;
      align-items: center;

      .icon {
        width: 40px;
        height: 40px;
        margin: 0;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      font-family: var(--font-normal);
      text-align: left;
      margin-top: 10px;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    .description {
      padding: 0;
      margin: 0;
      text-align: left;
      font-size: 13px;
      margin-top: 6px;
    }

    .spacing {
      margin-top: 30px;
    }

    .explore-link {
      display: flex;
      align-items: center;
      transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
      
      &:hover {
        transform: translateY(-3px); /* Moves the link slightly upwards */
        color: #ffba00; /* Changes the text color */
      }
    
      .explore-container {
        display: flex;
        align-items: center;
      }
    
      .arrow {
        width: 20px;
        height: 20px;
        margin-bottom: 0;
        transition: transform 0.3s ease; /* Smooth transition for the arrow */
      }
    
      &:hover .arrow {
        transform: translateX(5px); /* Moves the arrow to the right when hovered */
      }
    }
    
  }

  img {
    margin-bottom: 20px;
    width: 160px;
    height: 160px;
  }
`;


const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;

  svg,
  img {
    width: 50px;
    height: 50px;
    transition: transform 0.3s ease, filter 0.3s ease;

    &:hover {
      transform: scale(1.2);
      filter: brightness(1.2);
    }
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;

    svg,
    img {
      width: 40px;
      height: 40px;
    }
  }
`;



const BlockThree: React.FC = () => {
  
  return (
    <div className="blockthree-wrapper apoplsp" style={{margin:0}}>
       <MainWrapper>
<h1>Key Benefits of <span style={{color:'#ffba00'}}>Baaz Coin</span></h1>
<p>Why Choose Baaz Coin for Your Crypto Transactions?</p>
<Audits>
<div className='auditkyc'>
  <div className='icon-container'>
    <img src={customer} className='icon' />
  </div>
  <h1 className='title'>Convenient Payments</h1>
  <p className='description'>Experience hassle-free transactions with a variety of payment options designed to suit your lifestyle. Whether you're shopping online, paying bills, or sending money to loved ones, our streamlined system ensures fast and efficient processing every time.</p>
  {/* <div className='spacing'></div>
  <a href="/" className='explore-link'>
    <div className='explore-container'>
      Explore More
      <img className='arrow' src={Arrow} />
    </div>
  </a> */}
</div>

<div className='auditkyc'>
  <div className='icon-container'>
    <img src={bitcoin} className='icon' />
  </div>
  <h1 className='title'>Enhanced Security</h1>
  <p className='description'>Rest easy knowing your personal and financial information is protected by advanced encryption technologies. With multiple layers of security, including fraud detection and real-time alerts, we prioritize keeping your data safe while offering you peace of mind.</p>
  {/* <div className='spacing'></div>
  <a href="/" className='explore-link'>
    <div className='explore-container'>
      Explore More
      <img className='arrow' src={Arrow} />
    </div>
  </a> */}
</div>

<div className='auditkyc'>
  <div className='icon-container'>
    <img src={elements} className='icon' />
  </div>
  <h1 className='title'>User-Friendly Interface</h1>
  <p className='description'>Enjoy effortless navigation with an intuitive design that simplifies every step. Clear layouts and accessible features ensure a smooth experience for users of all skill levels. Whether managing tasks or exploring options, everything you need is just a click away.</p>
  {/* <div className='spacing'></div>
  <a href="/" className='explore-link'>
    <div className='explore-container'>
      Explore More
      <img className='arrow' src={Arrow} />
    </div>
  </a> */}
</div>



</Audits>


       </MainWrapper>
    </div>
  );
};

export default BlockThree;
