import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Web3Button } from "@web3modal/react";

import Buy from "./BuyCrypto";

import Crypto from "./Crypto";

const Label = styled.label`
  display: block;
  margin: 12px 0;
  font-size: 16px;
  color: #fff;

`;

const MainWrapper = styled.div`
  padding: 120px 0px;
  max-width: 100%;
  border-radius: 20px;

  h1 {
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 45px;
    font-family: var(--font-bold);
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  p {
    text-align: center;
    font-size: 17px;
    color: #ccc;
    margin: 0 30%;

    @media (max-width: 768px) {
      margin: 0;
      font-size: 13px;
    }
  }

  .audit-1 {
    padding: 20px;
    @media (max-width: 768px) {
      padding: 10px;
    }
    h2 {
      font-family: var(--font-bold);

      font-weight: 700;
      text-align: center;
      font-size: 27px;
    }
  }

  .audit-2 {
    padding: 20px;
    @media (max-width: 768px) {
      padding: 10px;
    }
  }

  .button-select {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: rgba(38, 38, 38, 0.6); /* #262626 with 10% opacity */
    padding: 7px;
    border-radius: 10px;

    .button-selection {
      width: 70px;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease; /* Add smooth animation */
    }

    .button-selection.active {
      background-color: rgba(255, 186, 0, 0.8);
      transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for active state */
    }
  }

  .audio {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .timer {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .time-block {
    background-color: rgba(38, 38, 38, 0.6); /* #262626 with 10% opacity */
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    width: 80px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      width: 40px;
    }
  }

  .time-block span:first-child {
    font-size: 2rem;
    font-weight: bold;
    display: block;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  @media (max-width: 600px) {
    .time-block {
      margin-bottom: 10px;
    }
  }
`;

const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 80%;
  grid-template-columns: 1fr 1fr;
  background-color: rgba(38, 38, 38, 0.4);
  border-radius: 20px;
  backdrop-filter: blur(1px);
  border-radius: 15px;
  border: 1px solid #606060;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;    row-gap: 20px;
    width: 95%;

    padding: 20px 0px;
  }

  .auditkyc {
    padding: 35px;
    @media (max-width: 768px) {
      padding: 25px;
    }

    .icon-container {
      width: fit-content;
      padding: 7px;
      display: flex;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      background-color: #303030;
      border: 1px solid #606060;
    }

    img {
      width: 40px;
      height: 40px;
      margin: 0;
      @media (max-width: 768px) {
        width: 30px;
        height: 30px;
      }
    }

    h1 {
      font-size: 26px;
      font-weight: 600;
      font-family: var(--font-normal);
      text-align: left;
      margin-top: 10px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    p {
      padding: 0;
      margin: 0;
      text-align: left;
      font-size: 14px;
      margin-top: 10px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`;

const BlockThree: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [selected, setSelected] = useState("crypto");

  const handleSelection = (option: string) => {
    setSelected(option);
  };

  useEffect(() => {
    const targetDate = new Date("2025-02-01T18:40:00Z").getTime();
  
    const updateTimer = () => {
      const now = new Date();
      const timeDifference = targetDate - now.getTime();
  
      if (timeDifference <= 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        clearInterval(intervalId);
        return;
      }
  
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  
      setTimeLeft({ days, hours, minutes, seconds });
    };
  
    const intervalId = setInterval(updateTimer, 1000);
    updateTimer(); // Initial call to set time immediately
  
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);
  

  return (
    <div className="blockthree-wrapper apoplo">
      <MainWrapper>
        <h1>
          Baaz Coin <span style={{ color: "#ffba00" }}>Presale</span>
        </h1>
        <p>
          Fully AI-Powered POS – Designed for the Future of Customer Service and
          Digital Payments
        </p>
        <div className="audio">
          <Audits>
            <div className="audit-1">
              <h2>
                How to buy{" "}
                <span style={{ color: "#ffba00" }}> Baaz Coin?</span>
              </h2>

              <Buy/>
            </div>
            <div className="audit-2">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                 
                </div>
                {selected === "crypto" ? (
                  <>
                    <Web3Button />
                  </>
                ) : null}
              </div>

              <p style={{ color: "white", marginBottom: 20, marginTop: 20 }}>
                Time Left
              </p>
              <div className="timer">
                <div style={{ textAlign: "center" }}>
                  <div className="time-block">
                    <span>{timeLeft.days}</span>
                  </div>
                  Day
                </div>
                <div style={{ textAlign: "center" }}>
                  <div className="time-block">
                    <span>{timeLeft.hours}</span>
                  </div>
                  Hours
                </div>
                <div style={{ textAlign: "center" }}>
                  <div className="time-block">
                    <span>{timeLeft.minutes}</span>
                  </div>
                  Min
                </div>
                <div style={{ textAlign: "center" }}>
                  <div className="time-block">
                    <span>{timeLeft.seconds}</span>
                  </div>
                  Sec
                </div>
              </div>
            

              {selected === "crypto" ? (
                  <>
                    <Crypto />
                  </>
                ) : <div style={{display:'flex',justifyContent:'center',alignItems:'center',padding:40}}>
                <Label>Will be Live Soon</Label></div>}
             
            </div>
          </Audits>
        </div>
      </MainWrapper>
    </div>
  );
};

export default BlockThree;
