import React, { useState } from "react";
import { FaTelegram } from "react-icons/fa";
import "./TelegramIcon.css";

const TelegramIcon: React.FC = () => {
  const [showText, setShowText] = useState(false);

  return (
    <div
      className="telegram-icon-container"
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
    >
      <a
        href="https://t.me/baazcoin" // Replace with your Telegram link
        target="_blank"
        rel="noopener noreferrer"
        className="telegram-icon"
      >
        <FaTelegram size={30} />
      </a>
      <div className={`animated-text ${showText ? "visible" : ""}`}>
        Join Baaz Coin Official Telegram
      </div>
    </div>
  );
};

export default TelegramIcon;
