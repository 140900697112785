import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import styled, { css } from 'styled-components';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);



const MainWrapper = styled.div`
width:300px;
@media (max-width: 768px) {
  width:230px;
margin-top:20px;
}

`;

const TokenomicsChart: React.FC = () => {
  const data = {
    labels: [
      'Presale',
      'IEO',
      'Private Sale',
      'Airdrop',
      'Blockchain Development',
      'Advisor',
      'Staking',
      'Founder & Team',
      'Validator Reward',
      'Data Provider Reward',
    ],
    datasets: [
      {
        label: '% of BaazCoin',
        data: [30, 5, 30, 2.5, 10, 5, 5, 5, 5, 2.5],
        backgroundColor: [
          'rgba(196, 166, 102, 0.8)', // #ffc107
          'rgba(186, 156, 90, 0.8)',  // #ba9c5a
          'rgba(218, 177, 108, 0.8)', // #dab16c
          'rgba(210, 170, 90, 0.8)',  // #d2aa5a
          'rgba(230, 194, 117, 0.8)', // #e6c275
          'rgba(184, 155, 82, 0.8)',  // #b89b52
        ],
        borderColor: [
          'rgba(196, 166, 102, 0.8)', // #ffc107
          'rgba(186, 156, 90, 0.8)',  // #ba9c5a
          'rgba(218, 177, 108, 0.8)', // #dab16c
          'rgba(210, 170, 90, 0.8)',  // #d2aa5a
          'rgba(230, 194, 117, 0.8)', // #e6c275
          'rgba(184, 155, 82, 0.8)',  // #b89b52
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <MainWrapper className="chart-container">
      <Doughnut data={data} options={options} />
    </MainWrapper>
  );
};

export default TokenomicsChart;
