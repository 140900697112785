import React from "react";
import styled from "styled-components";
import t1 from '../../assets/images/t1.png'
import t2 from '../../assets/images/t2.png'
import t3 from '../../assets/images/t3.png'
import sam from '../../assets/images/sam.png'

const Main = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
   flex-direction:column;
   height: 1120px;
   gap:40px;

  }
`;

const ProfileCard = styled.div`
  position: relative;
  font-family: sans-serif;
  width: 220px;
  height: 220px;
  background: #fff;
  padding: 30px;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: 0.6s;
  margin: 0 25px;

  &:hover {
    border-radius: 10px;
    height: 260px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.6s;
  z-index: 99;

  ${ProfileCard}:hover & {
    transform: translateY(-60px);
  }

  img {
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 22px #3336;
    transition: 0.6s;

    ${ProfileCard}:hover & {
      border-radius: 10px;
    }
  }
`;

const Caption = styled.div`
  text-align: center;
  transform: translateY(-80px);
  opacity: 0;
  transition: 0.6s;

  ${ProfileCard}:hover & {
    opacity: 1;
  }

  h3 {
    font-size: 16px;
    color: #ffba00;
    font-family: var(--font-bold);
  }

  p {
    font-size: 12px !important;
    color: #0c52a1;
    font-family: var(--font-regular);
    margin: 2px 0 9px 0;
  }

  .social-links a {
    color: #333;
    margin-right: 15px;
    font-size: 21px;
    transition: 0.6s;

    &:hover {
      color: #0c52a1;
    }
  }
`;

const teamMembers = [
  {
    name: "Nirvair Gill",
    role: "Founder & CEO",
    imgSrc:
     t3,
    socials: {

      facebook: "https://www.linkedin.com/in/nirvair-gill-452347309",
      instagram: "https://www.instagram.com/nirvair.gill92",
      twitter:"https://x.com/nirvair_g?s=11"
    },
  },
  {
    name: "Mentor Sadaiku",
    role: "Backend Developer",
    imgSrc:
      t2,
    socials: {
      facebook: "https://www.linkedin.com/in/mentor-sadiku/",
    },
  },
  {
    name: "Thinesh Rajabalah",
    role: "Blockchain Developer",
    imgSrc:
      t1,
    socials: {
      facebook: "https://de.linkedin.com/in/thinesh-rajabalah",
    },
  },
  {
    name: "Samiksha Negi",
    role: "Chief Marketing Officer",
    imgSrc:
      sam,
    socials: {
      facebook: "https://www.linkedin.com/in/samikshanegi5",
    },
  },
];

const TeamSection: React.FC = () => {
  return (
    <Main>
      {teamMembers.map((member, index) => (
        <ProfileCard key={index}>
          <ImageWrapper>
            <img src={member.imgSrc} alt={member.name} />
          </ImageWrapper>
          <Caption>
            <h3>{member.name}</h3>
            <p>{member.role}</p>
            <div className="social-links">
              {member.socials.facebook && (
                <a href={member.socials.facebook}>
                  <i className="fab fa-linkedin"></i>
                </a>
              )}
              {member.socials.instagram && (
                <a href={member.socials.instagram}>
                  <i className="fab fa-instagram"></i>
                </a>
              )}
              {member.socials.twitter && (
                <a href={member.socials.twitter}>
                  <i className="fab fa-x-twitter"></i>
                </a>
              )}
            </div>
          </Caption>
        </ProfileCard>
      ))}
    </Main>
  );
};

export default TeamSection;
