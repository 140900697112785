import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const MainWrapper = styled.div`
  margin: 50px 0px;
  max-width: 100%;
  border-radius: 20px;
  padding: 20px;
  grid-template-columns: 20% 80%;

  p {
    font-size: 12px;
    color: #c4c4c4;
    margin: 0px 0;
  }

  h1 {
    color: #fff;
    font-weight: 500;
    font-size: 35px;
    font-family: var(--font-bold);
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
`;

const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  padding: 20px 200px;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px 0;
  }

  .auditkyc {
    background-color: #262626;
    border-radius: 15px;
    border: 1px solid #606060;
    padding: 35px;
  }

  img {
    margin-bottom: 20px;
    width: 160px;
    height: 160px;
  }
`;

const BlockThree: React.FC = () => {
  const [stats, setStats] = useState<{
    total_addresses: string;
    total_transactions: string;
    transactions_today: string;
  } | null>(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch('https://baazscan.com/api/v2/stats');
        const data = await response.json();
        setStats({
          total_addresses: data.total_addresses,
          total_transactions: data.total_transactions,
          transactions_today: data.transactions_today,
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="blockthree-wrapper stats-back" style={{ margin: 0 }}>
      <MainWrapper>
        <Audits>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h1>Join Our Community of Millions</h1>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginLeft: 20 }}>
            <h1
              style={{
                background: 'linear-gradient(94.41deg, #F087FF 30.31%, #6E1FCE 82.77%, rgba(110, 31, 206, 0.1) 210.09%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                width: 'fit-content',
                color: 'transparent',
                fontSize: 100,
                fontFamily: 'var(--font-semibold)',
                margin: 0,
                padding: 0,
              }}
            >
              {stats ? `${stats.total_addresses}+` : '...'}
            </h1>
            <p>ACTIVE<br />ACCOUNTS</p>

            <h1
              style={{
                background: 'linear-gradient(92.73deg, #1FCFF1 35.99%, #234CB6 97.32%, rgba(35, 76, 182, 0.1) 189.09%)',
                WebkitBackgroundClip: 'text',
                width: 'fit-content',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                color: 'transparent',
                fontSize: 100,
                fontFamily: 'var(--font-semibold)',
                margin: 0,
                padding: 0,
              }}
            >
              {stats ? stats.total_transactions : '...'}
            </h1>
            <p>TOTAL<br />TRANSACTIONS</p>

            <h1
              style={{
                background: 'linear-gradient(92.24deg, #19FB9B 11.36%, #199890 54.3%, #005F59 100%)',
                WebkitBackgroundClip: 'text',
                width: 'fit-content',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                color: 'transparent',
                fontSize: 100,
                fontFamily: 'var(--font-semibold)',
                margin: 0,
                padding: 0,
              }}
            >
              {stats ? `${stats.transactions_today}` : '...'}
            </h1>
            <p>DAILY<br />TRANSACTIONS</p>
          </div>
        </Audits>
      </MainWrapper>
    </div>
  );
};

export default BlockThree;
