import React from 'react';
import styled from 'styled-components';

import customer from '../../assets/images/customer-support.png';
import elements from '../../assets/images/elements.png';
import bitcoin from '../../assets/images/bitcoin-credit-card.png';
import crown from '../../assets/images/crown.png';

const MainWrapper = styled.div`
  margin: 50px 0px;
  max-width: 100%;
  border-radius: 20px;
  padding: 20px;

  h1 {
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 45px;
    font-family: var(--font-bold);
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  p {
    text-align: center;
    font-size: 17px;
    color: #ccc;
    margin: 0 30%;

    @media (max-width: 768px) {
      margin: 0;
      font-size: 13px;
    }
  }
`;

const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  padding: 20px 200px;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px 0px;
  }

  .auditkyc {
    background-color: #262626b0;
    border-radius: 15px;
    border: 1px solid #606060;
    padding: 35px;
    @media (max-width: 768px) {
      padding: 25px;

    }

    .icon-container {
      width: fit-content;
      padding: 7px;
      display: flex;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      background-color: #303030;
      border: 1px solid #606060;
    }

    img {
      width: 40px;
      height: 40px;
      margin: 0;
      @media (max-width: 768px) {
        width: 30px;
        height: 30px;      }
    }

    h1 {
      font-size: 26px;
      font-weight: 600;
      font-family: var(--font-normal);
      text-align: left;
      margin-top: 10px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    p {
      padding: 0;
      margin: 0;
      text-align: left;
      font-size: 14px;
      margin-top: 10px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`;

const BlockThree: React.FC = () => {
  const auditData = [
    {
      image: customer,
      title: "Automated Customer Support",
      description:
        "Our POS system goes beyond processing payments. Fully AI-integrated, it can answer customer calls, address inquiries, and even take orders automatically – printing them directly to the kitchen. This hands-free approach minimizes wait times and enhances customer satisfaction.",
    },
    {
      image: bitcoin,
      title: "Seamless Crypto Payments",
      description:
        "Pay with crypto like never before. Our POS system enables transactions in Baaz Coin and other cryptocurrencies, giving customers a smooth, secure payment experience. Whether it’s a pizza or coffee, pay in crypto as easily as cash.",
    },
    {
      image: elements,
      title: "Crypto Card Integration",
      description:
        "Soon, Baaz will introduce a crypto card that functions like a traditional credit card, directly integrated with your digital wallet. This card lets you pay with crypto, making digital currency as convenient as a swipe or tap at any participating business.",
    },
    {
      image: crown,
      title: "Future-Ready Innovation",
      description:
        "Our AI POS system is just the beginning. The Baaz team is committed to rolling out new features that elevate the customer experience and expand payment possibilities.",
    },
  ];

  return (
    <div className="blockthree-wrapper apoplp" style={{ margin: 0 }}>
      <MainWrapper>
        <h1>
          About the AI-Powered <span style={{ color: '#ffba00' }}>POS System</span>
        </h1>
        <p>
          Fully AI-Powered POS – Designed for the Future of Customer Service and Digital Payments
        </p>
        <Audits>
          {auditData.map((audit, index) => (
            <div className="auditkyc" key={index}>
              <div className="icon-container">
                <img src={audit.image} alt={audit.title} />
              </div>
              <h1>{audit.title}</h1>
              <p>{audit.description}</p>
            </div>
          ))}
        </Audits>
      </MainWrapper>
    </div>
  );
};

export default BlockThree;
