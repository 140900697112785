import React, { useState } from "react";
import styled from "styled-components";
import Hero from "../../components/HeroHiring";
import BlockTwo from '../../components/BlockTwo';
import BlockThree from "../../components/BlockThirteen";
import BlockFour from "../../components/BlockTwelve";
import BlockFive from "../../components/BlockFive";
import BlockSix from "../../components/BlockFourteen";
import BlockSeven from "../../components/BlockSeven";
import BlockEight from "../../components/BlockFifteen";
import BlockNine from "../../components/BlockSixteen"
import BlockTen from "../../components/BlockTen"
import BlockEleven from "../../components/BlockEleven"




const Home: React.FC = () => {

  
 
  return (
    <div>
      <div>
      <div id="hero">
          <Hero />
        </div>       
        <BlockFour />             <BlockThree />   
        <BlockNine/>   <BlockEight/><BlockSix/><BlockEleven/>
     {/* <TransactionRow />
<AuditHome /> */}
      </div>
      </div>
  );
};

export default Home;
