import React, { useState, useEffect } from "react";
import copy from "../../assets/images/fi-br-copy.png";
import { useSnackbar } from 'notistack';

import "./Footer.scss";

const Footer: React.FC = () => {
  const [slicedAddress, setSlicedAddress] = useState<string>(
    "0x35ddF4cd864aCa66ebfED40bde62104C8aF2B175"
  );

  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = useState<string>("");
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleSubscribe = async (event: React.FormEvent) => {
    event.preventDefault();

    const response = await fetch("https://formspree.io/f/mnqkvegl", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });

    if (response.ok) {
      enqueueSnackbar("Subscription successful!", { variant: "success" });
      setEmail(""); // Clear the email input field
    } else {
      enqueueSnackbar("Something went wrong. Please try again.", { variant: "error" });
    }
  };


  const [copied, setCopied] = useState<boolean>(false);
  const fullAddress = "0x35ddF4cd864aCa66ebfED40bde62104C8aF2B175";

  useEffect(() => {
    const updateAddress = () => {
      if (window.innerWidth < 768) {
        setSlicedAddress(
          `${fullAddress.slice(0, 9)}...${fullAddress.slice(-6)}`
        );
      } else {
        setSlicedAddress(fullAddress);
      }
    };

    // Initial check
    updateAddress();

    // Add event listener for window resize
    window.addEventListener("resize", updateAddress);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", updateAddress);
    };
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(fullAddress).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Hide tooltip after 2 seconds
    });
  };

  return (
    <div className="footer-wrapper ">
      <div className="footer-grid">
     

        <div>
          <p style={{ fontSize: 14 }}>COMPANY</p>
          <div
            style={{
              fontSize: 14,
              padding: "20px 0px 5px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(222 222 222)" }} href="./#about">
              About
            </a>
          </div>
       
          <div
            style={{
              fontSize: 14,
              padding: "5px 0px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(222 222 222)" }} href="/hiring">
              Careers
            </a>
          </div>
          <div
            style={{
              fontSize: 14,
              padding: "5px 0px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(222 222 222)" }} href="mailto:support@baazcoin.com">
              Contact
            </a>
          </div>
        </div>

    
        <div>
          <p style={{ fontSize: 14 }}>DEVELOPERS</p>
          <div
            style={{
              fontSize: 14,
              padding: "20px 0px 5px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(222 222 222)" }} href="https://docs.baazcoin.com/">
              Documentation
            </a>
          </div>
          <div
            style={{
              fontSize: 14,
              padding: "5px 0px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(222 222 222)" }} href="https://baazscan.com/api-docs">
              API Reference
            </a>
          </div>
         
        </div>

        <div>
          <p style={{ fontSize: 14 }}>OTHERS</p>
          <div
            style={{
              fontSize: 14,
              padding: "20px 0px 5px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(222 222 222)" }} href="./privacy-policy">
              Privacy Policy
            </a>
          </div>
          <div
            style={{
              fontSize: 14,
              padding: "5px 0px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(222 222 222)" }} href="./terms-of-use">
              Terms of Use
            </a>
          </div>
          <div
            style={{
              fontSize: 14,
              padding: "5px 0px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(222 222 222)" }} href="./cookie-policy">
              Cookie Policy
            </a>
          </div>
         
        </div>

        
        <div>
          <p style={{ fontSize: 14 }}>ADDRESS</p>
        
        <p style={{ fontSize: 14,color:'rgb(222 222 222)',marginTop:20 }}><span style={{ color: "#ffba00", fontFamily: "var(--font-bold)" }}>BAAZ FOUNDATION LLC</span><br/>
📍 7901 4TH ST N
STE 300
ST. PETERSBURG, FL. US 33702
</p>
         
        </div>        <div>
          {/* <p style={{ fontSize: 14 }}>PLATFORM</p>
          <div
            style={{
              fontSize: 14,
              padding: "20px 0px 5px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(222 222 222)" }} href="/">
              Terms
            </a>
          </div>
          <div
            style={{
              fontSize: 14,
              padding: "5px 0px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(222 222 222)" }} href="/">
              Privacy
            </a>
          </div> */}
        </div>

        <div>
          <p style={{ fontSize: 14 }}>JOIN THE BAAZ COMMUNITY</p>
          <form onSubmit={handleSubscribe}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email"
                style={{
                  padding: "10px 15px",
                  fontSize: 12,
                  border: "1px solid #fff",
                  backgroundColor: "black",
                  color: "white",
                  borderTopLeftRadius: 50,
                  borderBottomLeftRadius: 50,
                  borderRight: "none",
                  outline: "none",
                  fontFamily: "var(--font-regular)",
                  flex: 1,
                }}
                required
              />
              <button
                type="submit"
                style={{
                  padding: "10px 20px",
                  fontSize: 12,
                  backgroundColor: "#fff",
                  color: "#000",
                  border: "1px solid #fff",
                  cursor: "pointer",
                  borderTopRightRadius: 50,
                  borderBottomRightRadius: 50,
                  outline: "none",
                  fontFamily: "var(--font-regular)",
                }}
              >
                Subscribe
              </button>
            </div>
          </form>

          <p style={{ fontSize: 12, color: "rgb(222 222 222)" }}>
          Be the first to hear about exclusive announcements, events, and opportunities in the Baaz Coin ecosystem.
          </p>
          <p
            style={{
              color: "rgb(222 222 222)",
              fontSize: 13,
              marginTop: 30,
              marginBottom: 20,
            }}
          >
            Donation : ❤️ <br />
            <span style={{ color: "#ffba00", fontFamily: "var(--font-bold)" }}>
              {" "}
              {slicedAddress}
              <button
                onClick={handleCopy}
                style={{
                  background: "transparent",
                  border: "0px",
                  width: 20,
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <img style={{ width: 12 }} src={copy} alt="Copy icon" />
                {copied && (
                  <span
                    style={{
                      position: "absolute",
                      top: "-25px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      background: "#262626",
                      color: "#fff",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      fontSize: "12px",
                      whiteSpace: "nowrap",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    Copied!
                  </span>
                )}
              </button>
            </span>
          </p>
        </div>
      </div>

      <hr className="custom-hr" />
      <div className="mx">
        <div className="footer-container">
          <div className="footer-content flex-item">
            <div className="footer-second-content flex-item">
              {/* <div className="app-icon flex-item">
                <AppStoreIcon />
                <GooglePayIcon />
              </div> */}

              <div>
                <p style={{ color: "white", fontSize: 13.5 }}>
                  ©2024 <a href="https://baazfoundation.com" style={{color:'#ffba00'}}>Baaz Foundation LLC</a>
                </p>
                <p className="para">
                Baaz Foundation LLC specializes in blockchain, Web3, and AI, delivering<br/> innovative solutions for a fast, secure, and decentralized future.
                </p>
              </div>
            </div>
            <div className="media flex-item">
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 10000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid rgb(211 211 211 / 28%)",
                }}
              >
                <a href="https://x.com/baaz_coin/status/1831678733196931569?s=46">
                  <i className="fab fa-x-twitter"></i>
                </a>
              </div>
              <div
                style={{
                  zIndex:1000,
                  width: 40,
                  height: 40,
                  borderRadius: 10000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid rgb(211 211 211 / 28%)",
                }}
              >
                <a href="https://t.me/baazcoin">
                  <i className="fab fa-telegram"></i>
                </a>
              </div>
            
              <div
                style={{
                  width: 40,
                  zIndex:1000,

                  height: 40,
                  borderRadius: 10000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid rgb(211 211 211 / 28%)",
                }}
              >
                <a href="https://www.youtube.com/@baazcoin">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
              <div
                style={{
                  width: 40,
                  zIndex:1000,

                  height: 40,
                  borderRadius: 10000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid rgb(211 211 211 / 28%)",
                }}
              >
                <a href="https://www.instagram.com/baaz.coin/?igsh=MWl3b2VkMm94MjFuOA%3D%3D#">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
