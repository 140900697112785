import React from "react";
import styled from "styled-components";

import computer from "../../assets/images/computer-programming-01.png";

const MainWrapper = styled.div`
  margin: 50px 0px;
  max-width: 100%;
  border-radius: 20px;
  padding: 20px;
  grid-template-columns: 20% 80%;

  p {
    text-align: center;
    font-size: 14px;
    color: #ccc;
    margin: 0 17%;
    @media (max-width: 768px) {
      margin: 0 5%;
      font-size: 12px;
      margin-top: 10px;
    }
  }

  h1 {
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 45px;
    font-family: var(--font-bold);
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
`;

const JobTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 100px;
  margin-bottom: 20px;

  .ooo{
    gap:40px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
    align-items: start;


    .ooo{
      flex-direction:column;
      gap:0px;
    }
  }

  .texture {
    h1 {
      font-size: 22px;
      @media (max-width: 768px) {
        font-size: 17px;
      }
    }

    p {
      margin-top: 5px;
      font-size: 15px;
      color: white;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`;

const JobDetail = ({
  title,
  experience,
  location,
}: {
  title: string;
  experience: string;
  location: string;
}) => (
  <JobTable>
    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
      <img
        src={computer}
        alt="Job Icon"
        style={{ width: "50px", height: "50px" }}
      />
      <div className="texture">
        <h1>{title}</h1>
        <div className="ooo" style={{ display: "flex", alignItems: "flex-start" }}>
          <p>
            Experience: <span style={{ color: "#ffba00" }}>{experience}</span>
          </p>
          <p>
            Location: <span style={{ color: "#ffba00" }}>{location}</span>
          </p>
        </div>
      </div>
    </div>
    <button
     onClick={() => window.location.href = "mailto:contact@baazcoin.com"}
      className={`button-prim`}
      style={{
        width: "fit-content",
        marginTop: 20,
        alignSelf: "center",
        padding: "10px 20px",
        fontSize: "14px",
      }}
    >
      Apply Now
    </button>
  </JobTable>
);

const BlockThree: React.FC = () => {
  return (
    <div className="blockthree-wrapper sdsdsd" style={{ margin: 0 }}>
      <MainWrapper>
        <h1>
          Find Your Place in the Future of{" "}
          <span style={{ color: "#ffba00" }}>Decentralization</span>
        </h1>
        <p>
          At Baaz Foundation, we’re looking for talented individuals across
          various technical fields. Below, you’ll find some of the key roles
          we’re currently hiring for:
        </p>
      </MainWrapper>
      <div>
        {/* Job 1 */}
        <JobDetail
          title="Blockchain Developers (Layer 2 Focus)"
          experience="4+ Years"
          location="Work from Home"
        />
        <hr className="custom-hr" style={{ marginTop: 20, margin: "20px 6%" }} />

        {/* Job 2 */}
        <JobDetail
          title="Full Stack Developers"
          experience="4+ Years"
          location="Work from Home"
        />
        <hr className="custom-hr" style={{ marginTop: 20, margin: "20px 6%" }} />

        {/* Job 3 */}
        <JobDetail
          title="Smart Contract Developers"
          experience="2+ Years"
          location="Remote"
        />
        <hr className="custom-hr" style={{ marginTop: 20, margin: "20px 6%" }} />

        {/* Job 4 */}
        <JobDetail
          title="AI & Machine Learning Engineers"
          experience="2+ Years"
          location="On-site / Remote"
        />
        <hr className="custom-hr" style={{ marginTop: 20, margin: "20px 6%" }} />
      </div>
    </div>
  );
};

export default BlockThree;
