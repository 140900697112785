import { ethers, formatUnits,formatEther } from "ethers";
import { erc20ABI } from "wagmi";
import { TOKEN_ADDRESS } from "./Address";
const getSigner = async (address: string) => {
  const windowEth = window as any;
  const provider = new ethers.BrowserProvider(windowEth.ethereum);
  return await provider.getSigner(address);
};

export const getBalance = async (address: string) => {
  const signer = await getSigner(address);

  const tokenContract = new ethers.Contract(TOKEN_ADDRESS, erc20ABI, signer);

  const balance = await tokenContract.balanceOf(address);

  return Number(formatEther(balance.toString()));
};

export const getTokenInfo = async (address: string, tokenAddress: string) => {
  const signer = await getSigner(address);

  const tokenContract = new ethers.Contract(tokenAddress, erc20ABI, signer);

  const balance = await tokenContract.balanceOf(address);
  const symbol = await tokenContract.symbol();
  const decimals = await tokenContract.decimals();

  return {
    symbol,
    decimals: decimals.toString(),
    balance: Number(formatUnits(balance.toString(), decimals)),
    tokenAddress,
  };
};

export const getIsValidToken = async (address: string, tokenAddress: string) => {
  const signer = await getSigner(address);

  const tokenContract = new ethers.Contract(tokenAddress, erc20ABI, signer);

  try {
    await tokenContract.totalSupply();
    return true;
  } catch (error) {
    return false;
  }
};
