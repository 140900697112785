import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';

import room from '../../assets/images/room.png'

const MainWrapper = styled.div`
margin: 0px 0px;
max-width: 100%;
  border-radius: 20px;
  padding: 20px;
  grid-template-columns: 20% 80%;
  
`;

const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  padding: 20px 200px;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px 00px;

  }
`;

const AuditImage = styled.img`
  margin-bottom: 20px;
  width: 90%;
`;

const AuditContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
`;

const Title = styled.h1`
  color: #fff;
  font-weight: 500;
  font-size: 35px;
  font-family: var(--font-bold);

  span {
    color: #ffba00;
  }

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const Description = styled.p`
  font-size: 17px;
  color: #ccc;
  margin: 20px 0;
`;



const BlockThree: React.FC = () => {
  return (
    <div className="blockthree-wrapper apoplps" style={{ margin: 0 }}>
      <MainWrapper>
        <Audits>
          <AuditImage src={room} />

          <AuditContent>
            <Title>How <span>Baaz Pay</span> Works</Title>
            <Description>
            Baaz Pay is our cutting-edge crypto wallet, designed with an intuitive extension for seamless access. It comes equipped with powerful APIs and webhook integrations, enabling effortless connectivity and functionality for developers and businesses. To enhance convenience, Baaz Coin also offers a linked credit card, allowing you to manage and spend your crypto effortlessly, anywhere. Simplify transactions and bridge the gap between traditional and digital finance with Baaz Coin            </Description>
            <button  
 onClick={() => window.location.href = "https://docs.baazcoin.com/utility-of-baaz/pay-with-crypto"}

            className={`button-prim `}
            style={{width:'fit-content'}}
          >
            <div style={{display:'flex',fontSize:16,justifyContent:'center',alignItems:'center',margin:0,padding:0}}>
Explore BaazPay            </div>
          </button>
          </AuditContent>
        </Audits>
      </MainWrapper>
    </div>
  );
};

export default BlockThree;
