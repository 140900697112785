import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';

import room from '../../assets/images/baaz-graphic.png'

const MainWrapper = styled.div`
  margin: 50px 0px;
  max-width: 100%;
  border-radius: 20px;
  padding: 20px;
  grid-template-columns: 20% 80%;
  
`;

const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  padding: 20px 130px;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px 00px;

  }
`;

const AuditImage = styled.img`
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
`;

const AuditContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
`;

const Title = styled.h1`
  color: #fff;
  font-weight: 500;
  font-size: 35px;
  font-family: var(--font-bold);

  span {
    color: #ffba00;
  }

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const Description = styled.p`
  font-size: 15px;
  color: #ccc;
  margin: 20px 0;
`;



const BlockThree: React.FC = () => {
  return (
    <div className="blockthree-wrapper apoplps" style={{ margin: 0 }}>
      <MainWrapper>
        <Audits>
          <AuditImage src={room} />

          <AuditContent>
            <Title>Why We’re <span>Different</span></Title>
            <Description>
            Baaz Coin is not like other projects that launch simple tokens. We are launching a full-fledged Coin directly on our own Layer 1 blockchain, the Baaz Chain. This is a first-of-its-kind project, and our transparent and accessible team is committed to building an ecosystem that benefits everyone.
<br/><br/>
Now, we’re looking for skilled, passionate individuals to join us in making this vision a reality. We are actively hiring across several categories, and if you’re a developer, a blockchain enthusiast, or simply someone who wants to be a part of a project that will change the world, we want to hear from you.            </Description>
          
          </AuditContent>
        </Audits>
      </MainWrapper>
    </div>
  );
};

export default BlockThree;
