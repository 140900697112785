import React from 'react';
import styled from 'styled-components';

const PolicyWrapper = styled.div`
  margin: 50px auto;
  max-width: 90%;
  padding: 20px;
  color: #fff;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffba00;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Section = styled.div`
  margin-bottom: 25px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  margin-left: 40px;
  line-height: 1.6;
  color: #ccc;

  strong {
    color: #ffba00;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const PrivacyPolicy: React.FC = () => {
  return (
    <PolicyWrapper>
      <Paragraph>
        <strong>Last Updated:</strong> December 4, 2024
      </Paragraph>
      <Paragraph>
        At Baaz Foundation LLC ("we," "our," or "us"), we prioritize your privacy and are committed to safeguarding the
        personal information you provide to us. This Privacy Policy outlines how we collect, use, disclose, and protect
        your data when you interact with Baaz Coin, our blockchain, and related services. By using our services, you
        consent to the terms outlined in this Privacy Policy.
      </Paragraph>
      <Paragraph>
        <strong>Baaz Foundation LLC</strong> is a registered entity based at:
        <br />
        7901 4th St N, Ste 300, St. Petersburg, FL, US 33702.
        <br />
        This address also serves as the headquarters for all legal and operational matters related to Baaz Coin.
      </Paragraph>
      <Section>
        <SectionTitle><br/>1. Information We Collect</SectionTitle>
        <Paragraph>
          We may collect the following types of data to enhance our services and ensure compliance with legal
          requirements:
          <br />
          <strong>1.1. Personal Information</strong>
          <br />
          - Identity Data: Name, email address, phone number, and government-issued ID (if required for regulatory
          compliance).
          <br />
          - Contact Data: Mailing address and country of residence.
          <br />
          - Payment Data: Cryptocurrency wallet addresses and transaction details.
          <br />
          <strong>1.2. Non-Personal Information</strong>
          <br />
          - Technical Data: IP address, device type, browser type, operating system, and connection information.
          <br />
          - Usage Data: Pages visited, features used, and time spent on the platform.
          <br />
          <strong>1.3. Blockchain Data</strong>
          <br />
          - Since Baaz Coin operates on a decentralized blockchain, all transactions are publicly visible on the
          blockchain ledger. This data may include wallet addresses, transaction amounts, and timestamps.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>2. How We Use Your Information</SectionTitle>
        <Paragraph>
          We utilize the data we collect for the following purposes:
          <br />
          <strong>2.1. Service Provision and Enhancement</strong>
          <br />
          - To enable transactions on the Baaz Coin blockchain.
          <br />
          - To improve our platform's user experience and performance.
          <br />
          <strong>2.2. Compliance and Security</strong>
          <br />
          - To fulfill legal obligations, including anti-money laundering (AML) and know-your-customer (KYC)
          requirements.
          <br />
          - To protect against fraud, unauthorized transactions, and security threats.
          <br />
          <strong>2.3. Communication</strong>
          <br />
          - To notify you about updates, changes, or promotional offers related to Baaz Coin.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>3. Sharing Your Information</SectionTitle>
        <Paragraph>
          We do not sell or rent your personal data to third parties. However, we may share your information under the
          following circumstances:
          <br />
          <strong>3.1. Legal and Regulatory Requirements</strong>
          <br />
          - To comply with laws, regulations, or government requests, your data may be shared with legal authorities.
          <br />
          <strong>3.2. Service Providers</strong>
          <br />
          - We may share your data with trusted third-party vendors who assist us in operating our platform (e.g.,
          hosting providers, KYC processors).
          <br />
          <strong>3.3. Corporate Changes</strong>
          <br />
          - In the event of a merger, acquisition, or sale of assets, your information may be transferred to the
          acquiring entity.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>4. Your Rights</SectionTitle>
        <Paragraph>
          You have the following rights concerning your personal data:
          <br />
          <strong>4.1. Access and Rectification</strong>
          <br />
          - You can request access to your data and correct inaccuracies.
          <br />
          <strong>4.2. Erasure</strong>
          <br />
          - You may request the deletion of your data unless it is required for regulatory or operational purposes.
          <br />
          <strong>4.3. Data Portability</strong>
          <br />
          - You can request a copy of your data in a machine-readable format.
          <br />
          <strong>4.4. Opt-Out</strong>
          <br />
          - You may opt-out of receiving promotional communications at any time by contacting us.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>5. Data Security</SectionTitle>
        <Paragraph>
          We implement robust measures to protect your information against unauthorized access, loss, or misuse.
          However, no system can guarantee 100% security.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>6. Retention of Data</SectionTitle>
        <Paragraph>
          We retain your data only for as long as necessary to provide our services and comply with legal obligations.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>7. International Data Transfers</SectionTitle>
        <Paragraph>
          If you access Baaz Coin services from outside the United States, your data may be transferred to and processed
          in the U.S., where our servers are located.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>8. Cookies and Tracking Technologies</SectionTitle>
        <Paragraph>
          We use cookies and similar technologies to enhance your experience on our platform. You may disable cookies in
          your browser settings, but this may impact the platform's functionality.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>9. Updates to This Privacy Policy</SectionTitle>
        <Paragraph>
          We may revise this Privacy Policy periodically. Updates will be posted on our website with the "Last Updated"
          date.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>10. Contact Us</SectionTitle>
        <Paragraph>
          For inquiries regarding this Privacy Policy or your personal data, please contact us at:
          <br />
          <strong>Baaz Foundation LLC</strong>
          <br />
          7901 4th St N, Ste 300,
          <br />
          St. Petersburg, FL, US 33702
          <br />
          Email: <a href="mailto:contact@baazcoin.com">contact@baazcoin.com</a>
        </Paragraph>
      </Section>
    </PolicyWrapper>
  );
};

export default PrivacyPolicy;
